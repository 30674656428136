import { IAppRule } from "Entities/AuthFactory/rule";
import React from "react";
import { Navigate } from "react-router-dom";
import JwtTokenStore from "Stores/JwtStore";
import Module from "../Module";

export enum RulesMode {
	OPTIONAL = "optional",
	NECESSARY = "necessary",
}

type IProps = {
	isPage?: boolean;
	mode: RulesMode;
	rules: IAppRule[];
	no?: boolean;
};

type IState = {
	show: boolean;
};

export default class Rules extends React.Component<IProps, IState> {
	private removeOnUserChange = () => {};

	constructor(props: IProps) {
		super(props);
		const show = this.getShowValue();
		this.state = {
			show: this.props.no ? !show : show,
		};
	}

	public override render() {
		if (!this.state.show && this.props.isPage) {
			return <Navigate to={Module.config.pages.Home.props.path} />;
		}

		if (!JwtTokenStore.getInstance().accessToken || !this.state.show) {
			return null;
		}

		return this.props.children;
	}

	public override componentDidMount() {
		this.removeOnUserChange = JwtTokenStore.getInstance().onChange(() => this.onUserRolesChange());
	}

	public override componentWillUnmount() {
		this.removeOnUserChange();
	}

	private onUserRolesChange() {
		const show = this.getShowValue();
		this.setState({
			show: this.props.no ? !show : show,
		});
	}

	private getShowValue() {
		if (this.props.mode === RulesMode.NECESSARY) {
			return this.props.rules.every((rule) => JwtTokenStore.getInstance().hasRule(rule.name, rule.action));
		}
		return !!this.props.rules.find((rule) => JwtTokenStore.getInstance().hasRule(rule.name, rule.action));
	}
}
