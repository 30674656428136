import React from "react";

import dark from "assets/images/logos/dark.svg";
import light from "assets/images/logos/light.svg";
import logoAnimationDark from "assets/images/logos/logo-animation-dark.gif";
import logoAnimationLight from "assets/images/logos/logo-animation-light.gif";
import ThemeMode, { EThemeModeType } from "Stores/ThemeMode";

import classes from "./classes.module.scss";
import Module from "../Module";

type IProps = {
	variant: "small" | "classic";
};

type IState = {
	theme: EThemeModeType;
};

export default class Logo extends React.Component<IProps, IState> {
	private removeOnSwitch = () => {};

	static defaultProps: IProps = {
		variant: "classic",
	};

	constructor(props: IProps) {
		super(props);
		this.state = {
			theme: ThemeMode.getInstance().type,
		};
	}

	public override render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				{this.getLogo()}
				{ this.props.variant=== 'classic' && Module.config.Logo.props.beta && <p>beta</p>}
			</div>
		);
	}

	public override componentDidMount() {
		this.removeOnSwitch = ThemeMode.getInstance().onSwitch((theme) => this.setState({ theme }));
	}

	public override componentWillUnmount() {
		this.removeOnSwitch();
	}

	private getLogo() {
		if (this.state.theme === EThemeModeType.LIGHT)
			return (
				<>
					<img alt="logo animated" src={logoAnimationLight} width={30} />;
					{this.props.variant === "classic" && <img alt="logo" src={light} width="124px" />};
				</>
			);
		return (
			<>
				<img alt="logo animated" src={logoAnimationDark} width={30} />;
				{this.props.variant === "classic" && <img alt="logo" src={dark} width="124px" />};
			</>
		);
	}
}
