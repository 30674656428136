import classNames from "classnames";
import I18n from "Components/Materials/I18n";
import React from "react";
import { NavLink, To } from "react-router-dom";

import classes from "./classes.module.scss";

type IState = {};
type IProps = {
	icon?: JSX.Element;
	title?: string | JSX.Element | null;
	to: To;
	comingSoon?: boolean;
	onBoarding?: boolean;
	style?: React.CSSProperties;
	reduced?: boolean;
	isDisabled?: boolean;
};

export default class NavLinkElement extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.activeLink = this.activeLink.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<NavLink
				style={this.props.style}
				data-coming-soon={this.props.comingSoon}
				data-onboarding={this.props.onBoarding}
				className={this.activeLink}
				to={this.props.to}>
				{this.props.icon}
				<div className={classes["text-container"]} data-reduced={this.props.reduced ?? false}>
					<div className={classes["title-container"]}>{this.props.title && <p>{this.props.title}</p>}</div>
					{this.props.comingSoon && (
						<div className={classes["subtitle"]}>
							<I18n map="general_text.coming_soon" />
						</div>
					)}
					{this.props.onBoarding && (
						<div className={classes["subtitle"]}>
							<I18n map="general_text.onboarding" />
						</div>
					)}
				</div>
			</NavLink>
		);
	}

	private activeLink({ isActive }: { isActive: boolean }) {
		return classNames(classes["route"], {
			[classes["active-route"]!]: isActive,
			[classes["disabled"]!]: this.props.isDisabled,
		});
	}
}
