export interface IAppRule {
	name: AppRuleNames;
	action: AppRuleActions;
}

export enum AppRuleActions {
	canAskKyc = "can-ask-kyc",
	canAskKyb = "can-ask-kyb",
	createUserInfoSelf = "create-user-info-self",
	signinSelf = "signin-self",
	signoutSelf = "signout-self",
	createSelf = "create-self",
	read = "read",
	readSelf = "read-self",
	createUserInfo = "create-user-info",
	updateUserInfoSelf = "update-user-info-self",
	create = "create",
	update = "update",
	delete = "delete",
}

export enum AppRuleNames {
	users = "users",
	collections = "collections",
	messages = "messages",
	externalCollections = "external-collections",
	blacklistAssets = "blacklist-asset",
}
