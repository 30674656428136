import React from "react";
import classes from "./classes.module.scss";

import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import CollectionBadge from "../../Elements/CollectionBadge";
import I18n from "Components/Materials/I18n";
import ProgressBar from "../../Elements/ProgressBar";
import Module from "Components/Materials/Module";
import { Link } from "react-router-dom";
import UserStore from "Stores/UserStore";
import { IUser } from "Entities/AuthFactory/user";
import { NavigateFunction, useNavigate } from "react-router-dom";
import LazyImage from "Components/Elements/LazyImage";
import { ICollection } from "Entities/Collection/collection";
import { CollectionOriginPath } from "Components/MainRoutes";
import ViewMore from "../ViewMore";
import TagBadge from "Components/Elements/TagBadge";
import LikeButton from "../LikeButton";
import CollectionsLikeStore from "Stores/UserLike/CollectionsLike";
import UserLikeCollections from "Api/Factory/AppUserLike/UserLikeCollection";
import { ILiked } from "Entities/Like";

type IProps = {
	collection: ICollection;
	clickable: boolean;
	editable: boolean;
	originPath: CollectionOriginPath;
};

type IPropsClass = IProps & {
	navigate: NavigateFunction;
};

type IState = {
	user: IUser | null;
	likesCount: number;
	isLiked: boolean;
};

class CollectionBox extends React.Component<IPropsClass, IState> {
	private removeUserOnChange = () => {};
	private collectionsLikesRemoveOnChange = () => {};

	static defaultProps = {
		editable: true,
	};

	constructor(props: IPropsClass) {
		super(props);
		this.state = {
			user: UserStore.getInstance().user,
			likesCount: this.props.collection.likes.count,
			isLiked: this.isCollectionLiked(CollectionsLikeStore.getInstance().likes),
		};

		this.handleUserChange = this.handleUserChange.bind(this);
		this.navigate = this.navigate.bind(this);
		this.toggleCollectionLikedState = this.toggleCollectionLikedState.bind(this);
	}

	public override render(): JSX.Element {
		const collection = this.props.collection;
		const numberOfCollectibles: number = collection.totalSupply ?? 0;
		const showEditButton = this.state.user?.userAddress! === collection.owner?.userAddress;
		return (
			<div className={classes["root"]} data-clickable={this.props.clickable}>
				<div className={classes["navigator"]} onClick={this.navigate} />
				<section className={classes["left"]}>
					<div className={classes["container"]} onClick={this.navigate}>
						<div className={classes["scrollable-content"]}>
							<div className={classes["collection-name"]}>
								<CollectionBadge collectionStatus={collection.status} />
								<h1>{collection.name}</h1>
							</div>

							<p className={classes["collection-owner"]}>
								<I18n map="general_text.by" />
								&nbsp;
								<Link to={`/profile/${collection.owner?.userAddress}`}>
									<span className={classes["collection-owner-pseudo"]}>{collection.owner?.app_user_info?.pseudo}</span>
								</Link>
							</p>
							<div className={classes["tags"]}>
								{collection.appTags.map((tag) => (
									<TagBadge key={tag.id} text={tag.name} />
								))}
							</div>
							<div className={classes["background-linear"]} />
						</div>
					</div>
					<div className={classes["image-container-responsive"]}>
						{showEditButton && this.props.editable && (
							<Link
								className={classes["edit-icon"]}
								to={Module.config.pages.EditCollection.props.path.replace(":id", collection.id)}
								rel="noreferrer">
								<EditIcon />
							</Link>
						)}

						<LikeButton
							likesCount={this.state.likesCount}
							isLiked={this.state.isLiked}
							onClick={this.toggleCollectionLikedState}
							className={classes["like-button"]}
						/>

						<LazyImage
							className={classes["collection-image"]}
							src={collection.image?.formats?.small?.url ?? collection.picture ?? ""}
							alt={collection.name}
						/>
					</div>
				</section>

				<section className={classes["middle"]}>
					<div className={classes["number-collectibles"]}>
						<p className={classes["total"]}>
							<strong>{numberOfCollectibles} </strong>
							{<I18n map="general_text.collectibles" />}
						</p>
						<div className={classes["total-split"]}>
							<p className={classes["available"]}>
								<strong>{collection.amountAvailable ?? collection.totalSupply - collection.amountReserved} </strong>
								<I18n map="general_text.available" />
							</p>
							<p>/</p>
							<p className={classes["minted"]}>
								<strong>{collection.amountMinted} </strong>
								<I18n map="general_text.minted" />
							</p>
							<p>/</p>
							<p className={classes["reserved"]}>
								<strong>{collection.amountReserved} </strong>
								<I18n map="general_text.reserved" />
							</p>
						</div>
					</div>
					<ProgressBar
						assets={[
							{
								quantity: collection.amountAvailable ?? collection.totalSupply - collection.amountReserved,
								color: "var(--color-progress-bar-available)",
							},
							{ quantity: collection.amountMinted, color: "var(--color-progress-bar-minted)" },
							{
								quantity: collection.amountReserved,
								color: "var(--color-progress-bar-reserved)",
							},
						]}
					/>
					<ViewMore text={collection.description} maxHeight={66} />
				</section>

				<section className={classes["like-edit-container"]}>
					{showEditButton && this.props.editable && (
						<Link
							className={classes["edit-icon"]}
							to={Module.config.pages.EditCollection.props.path.replace(":id", collection.id)}
							rel="noreferrer">
							<EditIcon />
						</Link>
					)}
					<LikeButton
						likesCount={this.state.likesCount}
						isLiked={this.state.isLiked}
						onClick={this.toggleCollectionLikedState}
						className={classes["like-button"]}
					/>
				</section>

				<section className={classes["right"]}>
					<LazyImage
						className={classes["collection-image"]}
						src={
							collection.image?.formats?.large?.url ??
							collection.image?.formats?.small?.url ??
							collection.image?.url ??
							collection.picture ??
							""
						}
						alt={collection.name}
					/>
				</section>
			</div>
		);
	}
	public override async componentDidMount() {
		this.removeUserOnChange = UserStore.getInstance().onChange(this.handleUserChange);
		this.collectionsLikesRemoveOnChange = CollectionsLikeStore.getInstance().onChange((likes) => {
			const isLiked = this.isCollectionLiked(likes);
			this.setState({ isLiked });
		});
	}

	public override componentWillUnmount() {
		this.removeUserOnChange();
		this.collectionsLikesRemoveOnChange();
	}

	private isCollectionLiked(likes: ILiked<ICollection>[]) {
		return likes.find((like) => like.like === true && like.liked.id === this.props.collection.id) !== undefined;
	}

	private navigate() {
		const pageConfig = Module.config.pages;
		if (this.props.clickable) {
			if (this.props.originPath === CollectionOriginPath.MINT) {
				this.props.navigate(`${pageConfig.Mint.props.path}/collection/${this.props.collection.id}`);
			} else {
				this.props.navigate(`${pageConfig.MyCollections.props.path}/${this.props.collection.id}`);
			}
		}
	}

	private handleUserChange(user: IUser | null) {
		this.setState({
			user,
		});
	}

	private async toggleCollectionLikedState() {
		const id = +this.props.collection.id;
		await UserLikeCollections.getInstance().toggle({ id });
		this.setState((prevState) => ({ likesCount: prevState.likesCount + (prevState.isLiked ? -1 : 1) }));
	}
}

export default function CollectionBoxFunctional(props: IProps) {
	const navigate = useNavigate();
	return <CollectionBox {...props} navigate={navigate} />;
}

CollectionBoxFunctional.defaultProps = {
	editable: true,
};
