import { MichelsonMap } from "@taquito/taquito";
import { char2Bytes, buf2hex } from "@taquito/utils";
import * as api from "@tzkt/sdk-api";
import Config from "Configs/Config";
import LightCache from "Services/LightCache";
import BaseContract from "./BaseContract";

import { ICollection } from "Entities/Collection/collection";
import { IAsset } from "Entities/CollectionAsset/collectionAsset";

api.defaults.baseUrl = Config.getInstance().get().blockchain.tezos.tzktApi;

export default class FactoryContract extends BaseContract {
	protected static ctx: FactoryContract;
	protected readonly minteedContractCache: LightCache;
	protected static readonly QUERY_DELAY = 8;
	protected static readonly ALLOCATIONS_DECIMALS = 4;

	constructor() {
		super();
		this.minteedContractCache = LightCache.getNewNameSpace();
	}

	public async getCollectionByUuid(uuid: string) {
		const storage: Storage = await this.contract.storage();
		return await storage["uuid_collections"].get(uuid);
	}

	/**
	 * A collection is generated on-chain when the first asset of some collection
	 * see its "openMint" prpoerty being switched to true
	 */
	public async generateOnchainCollection(collection: ICollection, asset: IAsset, creatorAdress: string) {
		const revenueSharings: { [key: string]: number } = {};

		if (asset.assetRevenueSharings) {
			asset.assetRevenueSharings.forEach((revenueSharing) => {
				revenueSharings[revenueSharing.address] = (revenueSharing.percentage / 100) * Math.pow(10, FactoryContract.ALLOCATIONS_DECIMALS);
			});
		}

		let collectionParams;

		if(!collection.metadataUri){			
			collectionParams = {
				creator: creatorAdress,
				metadata: MichelsonMap.fromLiteral({
					"": buf2hex(Buffer.from("tezos-storage:contents")),
					contents: buf2hex(
						Buffer.from(
							JSON.stringify({
								name: collection.name,
								description: collection.description,
								type: "collectible",
								imageUri: collection.imageUri,
								displayUri: collection.imageUri,
								version: "1.0.0",
								homepage: "https://minteed.app/",
								interfaces: ["TZIP-012", "TZIP-016", "TZIP-021"],
							}),
						),
					),
				}),
				name: collection.name,
				uuid: collection.uuid,
				assets_infos: [
					{
						quantity: asset.totalSupply,
						quantity_reserved: asset.amountReserved ?? 0,
						initial_price: asset.mintPrice,
						is_mintable: true,
						max_per_wallet: asset.maxMintPerWallet ?? 1,
						currency: { xTZ: null },
						salemode: { fCFS: null },
						allocations: MichelsonMap.fromLiteral(revenueSharings),
						allocations_decimals: FactoryContract.ALLOCATIONS_DECIMALS,
						token_metas: MichelsonMap.fromLiteral({
							"": char2Bytes(asset.metadataUri),
						}),
						uuid: asset.uuid,
					},
				],
			};
		}
		else{			
			collectionParams = {
				creator: creatorAdress,
				metadata: MichelsonMap.fromLiteral({
					"": char2Bytes(collection.metadataUri),
					
				}),
				name: collection.name,
				uuid: collection.uuid,
				assets_infos: [
					{
						quantity: asset.totalSupply,
						quantity_reserved: asset.amountReserved ?? 0,
						initial_price: asset.mintPrice,
						is_mintable: true,
						max_per_wallet: asset.maxMintPerWallet ?? 1,
						currency: { xTZ: null },
						salemode: { fCFS: null },
						allocations: MichelsonMap.fromLiteral(revenueSharings),
						allocations_decimals: FactoryContract.ALLOCATIONS_DECIMALS,
						token_metas: MichelsonMap.fromLiteral({
							"": char2Bytes(asset.metadataUri),
						}),
						uuid: asset.uuid,
					},
				],
			};
		}

		

		try {
			return await this.contract.methodsObject.generateCollection(collectionParams).send();
		} catch (err: any) {
			throw new Error(err?.message);
		}
	}
}

