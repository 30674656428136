import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	text: string;
	type?: "light" | "dark";
};

export default class TagBadge extends React.Component<IProps> {
	static defaultProps: IProps = {
		text: "",
		type: "dark",
	};

	public override render(): JSX.Element | null {
		if (this.props.text === "") return null;
		return (
			<div className={classes["root"]} data-variant={this.props.type}>
				{this.props.text}
			</div>
		);
	}
}
