import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { ISigned } from "Entities/AuthFactory/token";
import { IUser } from "Entities/AuthFactory/user";
import { IPaginatedParams } from "Entities/PaginatedParam";
import { IPaginatedResult } from "Entities/PaginatedResult";

interface IGetQueryString {
	q?: {};
}
interface IGetAllParams {
	appCollection?: {
		id: number;
	};
	app_roles?: {
		name: { $eq: string };
	};
	app_user_info?: {
		tagsDefine: {
			name: { $eq: string };
		};
	};
	becomeCreatorAt?: {
		name: { $eq: string };
	};
}

type ISortParams = {
	[key in keyof IUser | "updatedAt" | "createdAt" | "becomeCreatorAt"]?: "asc" | "desc";
};

interface IPostParams extends ISigned {
	userAddress: string;
}
export default class User extends BaseApiService {
	static instance: User;
	protected static readonly baseUrl = Config.getInstance().get().api.authFactory.concat("/app-users/").concat(Config.getInstance().get().app);

	static getInstance() {
		if (!User.instance) {
			User.instance = new User();
		}

		return User.instance;
	}

	public async get(queryString: IGetQueryString): Promise<IPaginatedResult<IUser>> {
		const url = new URL(User.baseUrl);
		Object.entries(queryString).forEach(([key, value]) => url.searchParams.set(key, JSON.stringify(value)));
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getAll(params: IGetAllParams, pagination?: IPaginatedParams, sort?: ISortParams): Promise<IPaginatedResult<IUser>> {
		const url = new URL(User.baseUrl);

		if (params) {
			url.searchParams.set("q", JSON.stringify(params));
		}

		if (pagination) {
			url.searchParams.set("p", JSON.stringify(pagination));
		}

		if (sort) {
			url.searchParams.set("s", JSON.stringify(sort));
		}

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getRandom(count: number, params: IGetAllParams) {
		const url = new URL(User.baseUrl + "/random");

		url.searchParams.set("count", count.toString());

		if (params) {
			url.searchParams.set("q", JSON.stringify(params));
		}
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getCountAssetsByUserId(pseudo: string) {
		const user = await this.get({
			q: {
				app_user_info: {
					pseudo: { $eq: pseudo },
				},
			},
		});

		return user.data[0] ?? null;
	}

	public async getOneByPseudo(pseudo: string) {
		const user = await this.get({
			q: {
				app_user_info: {
					pseudo: { $eq: pseudo },
				},
			},
		});

		return user.data[0] ?? null;
	}

	public async getById(id: string): Promise<IUser | null> {
		const user = await this.get({
			q: {
				id,
			},
		});

		return user.data[0] ?? null;
	}

	public async getOneByEmail(email: string): Promise<IUser | null> {
		const user = await this.get({
			q: {
				app_user_info: {
					email: { $eq: email },
				},
			},
		});

		return user.data[0] ?? null;
	}

	public async getOneByUserAddress(userAddress: string) {
		const url = new URL(User.baseUrl.concat(`/${userAddress}`));
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async createCreator(body: FormData) {
		const url = new URL(User.baseUrl.concat("/create-creator"));
		try {
			return this.postFormDataRequest(url, body);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async post(params: IPostParams) {
		const url = new URL(User.baseUrl.concat("/"));
		try {
			return this.postRequest(url, params);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async findSearch(search: string) {
		return this.get({
			q: {
				$or: [
					{
						userAddress: { $containsi: search },
					},
					{
						app_user_info: {
							firstName: { $containsi: search },
						},
					},
					{
						app_user_info: {
							lastName: { $containsi: search },
						},
					},
					{
						app_user_info: {
							pseudo: { $containsi: search },
						},
					},
					{
						app_user_info: {
							companyName: { $containsi: search },
						},
					},
				],
			},
		});
	}
}
