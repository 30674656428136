import React from "react";

import Version, { IConfig } from "Stores/Version";

import ThemeMode from "../Stores/ThemeMode";
import GlobalContext, { defaultContext } from "./GlobalContext";
import MainRoutes from "./MainRoutes";
import UserStore from "Stores/UserStore";
import AssetsLikeStore from "Stores/UserLike/AssetsLike";
import CollectionsLikeStore from "Stores/UserLike/CollectionsLike";
import UsersLikeStore from "Stores/UserLike/UsersLike";
import Module from "./Materials/Module";
import TagManager from "react-gtm-module";
import JwtStore from "Stores/JwtStore";
import { JwtPair } from "Entities/AuthFactory/jwtPair";

type IProps = {};

type IState = {
	version: IConfig | null;
	hasAccessToken: boolean;
};

declare global {
	interface Window {
		intercomSettings: any;
	}
}

export default class Main extends React.Component<IProps, IState> {
	private removeThemeModeOnSwitch = () => {};
	private removeVersionOnSwitch = () => {};
	private userRemoveOnChange = () => {};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			version: Version.getInstance().version,
			hasAccessToken: !!JwtStore.getInstance().accessToken

		};
		this.updateThemeMode();
		this.updateVersion();
	}

	public override render(): JSX.Element |null {
		if (!this.state.hasAccessToken) return null;
		return (
			<GlobalContext.Provider value={defaultContext}>
				<MainRoutes />
			</GlobalContext.Provider>
		);
	}

	public override componentDidMount() {
		const INTERCOM_APP_ID = Module.config.Intercom.props.appId;
		JwtStore.getInstance().onChange((jwtPair: JwtPair | null) => {
			this.setState({hasAccessToken: !!jwtPair})
		})
		this.removeThemeModeOnSwitch = ThemeMode.getInstance().onSwitch(() => this.updateThemeMode());
		this.removeVersionOnSwitch = Version.getInstance().onChange(() => this.updateVersion());
		this.userRemoveOnChange = UserStore.getInstance().onChange((user) => {
			if (!user) {
				this.launchIntercom({
					app_id: INTERCOM_APP_ID,
					name: "there", // Full name
					created_at: new Date(), // Signup date as a Unix timestamp
				});
				return;
			}
			this.launchIntercom({
				app_id: INTERCOM_APP_ID,
				name: user.app_user_info?.pseudo, // Full name
				email: user.app_user_info?.email, // Email address
				created_at: new Date(), // Signup date as a Unix timestamp
			});
			AssetsLikeStore.getInstance().loadLikes(+user.id);
			CollectionsLikeStore.getInstance().loadLikes(+user.id);
			UsersLikeStore.getInstance().loadLikes(+user.id);
		});

		Module.config.GoogleTagManager.enabled && this.launchGoogleTagManager();

		this.launchIntercomForVisitorOnly();
	}

	public override componentWillUnmount() {
		this.removeThemeModeOnSwitch();
		this.removeVersionOnSwitch();
		this.userRemoveOnChange();
	}

	private launchGoogleTagManager() {
		const tagManagerArgs = {
			gtmId: Module.config.GoogleTagManager.props.containerId,
		};

		TagManager.initialize(tagManagerArgs);
	}

	private launchIntercomForVisitorOnly() {
		const APP_ID = Module.config.Intercom.props.appId;
		window.intercomSettings = {
			app_id: APP_ID,
		};

		(function () {
			var w = window as any;
			var ic = w.Intercom;
			if (typeof ic === "function") {
				ic("reattach_activator");
				ic("update", w.intercomSettings);
			} else {
				var d = document;
				var i: any = function () {
					i.c(arguments);
				};
				i.q = [];
				i.c = function (args: any) {
					i.q.push(args);
				};
				w.Intercom = i;
				var l = function () {
					var s = d.createElement("script");
					s.type = "text/javascript";
					s.async = true;
					s.src = "https://widget.intercom.io/widget/" + APP_ID;
					var x = d.getElementsByTagName("script")[0];
					x?.parentNode?.insertBefore(s, x);
				};
				if (w.attachEvent) {
					w.attachEvent("onload", l);
				} else {
					w.addEventListener("load", l, false);
				}
			}
		})();
	}

	private launchIntercom(settings?: any) {
		let w = window as any;
		let ic = w.Intercom;
		if (typeof ic === "function") {
			ic("reattach_activator");
			ic("update", settings);
		} else {
			let d = document;
			let i: any = function () {
				i.c(arguments);
			};
			i.q = [];
			i.c = function (args: any) {
				i.q.push(args);
			};
			w.Intercom = i;
			let l = function () {
				let s = d.createElement("script");
				s.type = "text/javascript";
				s.async = true;
				s.src = "https://widget.intercom.io/widget/sk0tztfx";
				let x = d.getElementsByTagName("script")[0];
				x?.parentNode?.insertBefore(s, x);
			};
			if (w.attachEvent) {
				w.attachEvent("onload", l);
			} else {
				w.addEventListener("load", l, false);
			}
		}
	}
	private updateThemeMode() {
		document.body.setAttribute("theme-mode", ThemeMode.getInstance().type);
	}

	private updateVersion() {
		this.setState({
			version: Version.getInstance().version,
		});
		document.body.setAttribute("version", Version.getInstance().version?.version ?? "");
	}
}
