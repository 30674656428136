import BaseApiService, { IResponse } from "Api/BaseApiService";
import Config from "Configs/Config";
import { IUser } from "Entities/AuthFactory/user";

type IPostAccount = IUser & IResponse;

export default class Account extends BaseApiService {
	static instance: Account;
	protected static readonly baseUrl = Config.getInstance().get().api.authFactory.concat("/app-users/").concat(Config.getInstance().get().app);

	static getInstance() {
		if (!Account.instance) {
			Account.instance = new Account();
		}

		return Account.instance;
	}

	public async get(): Promise<IPostAccount> {
		const url = new URL(Account.baseUrl.concat("/myAccount"));
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
