import React from "react";
import classes from "./classes.module.scss";

import SideMenuOpened from "./SideMenuOpened";
import SideMenuClosed from "./SideMenuClosed";

type IState = {};
type IProps = {
	isMenuDisabled?: boolean;
};

export default class SideMenu extends React.Component<IProps, IState> {
	static defaultProps: IProps = {		
		isMenuDisabled: false,
	};
	public override render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<SideMenuClosed isMenuDisabled = {this.props.isMenuDisabled}/>
				<SideMenuOpened isMenuDisabled = {this.props.isMenuDisabled}/>
			</div>
		);
	}
}
