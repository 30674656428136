import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";
import classes from "./classes.module.scss";
import BasePage from "../Base";
import Button from "Components/Elements/Button";
import I18n from "Components/Materials/I18n";
import { Link } from "react-router-dom";
import Module from "Components/Materials/Module";

type IProps = {};

export default class NotFound extends BasePage<IProps> {
	public override render(): JSX.Element {
		return (
			<DefaultTemplate title={I18n.translate("pages_title.not_found")}>
				<div className={classes["root"]}>
					<div className={classes["container"]}>
						<div className={classes["text"]}>
							<div className={classes["title"]}>404</div>
							<div className={classes["subtitle"]}>{I18n.translate("pages.not_found.subtile")}</div>
						</div>
						<Link to={Module.config.pages.Home.props.path}>
							<Button touppercase={"true"}>{I18n.translate("pages.not_found.button")}</Button>
						</Link>
					</div>
				</div>
			</DefaultTemplate>
		);
	}
}
