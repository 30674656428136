import Collection from "Api/Factory/AppCollection/Collection";
import CollectionList from "Components/Elements/CollectionList";
import { IPendingScroll } from "Components/Elements/InfinitScroll";
import InfinitScrollVertical from "Components/Elements/InfinitScroll/Vertical";
import { CollectionOriginPath } from "Components/MainRoutes";
import { ICollection } from "Entities/Collection/collection";
import { CollectionStatus } from "Entities/Collection/enums/collectionStatus";
import React from "react";

type IProps = {};

type IState = {
	hasMoreToLoad: boolean;
	collections: ICollection[];
	element: HTMLElement;
};

export default class CollectionsOnSale extends React.Component<IProps, IState> {
	private static PAGE_SIZE = 10;
	constructor(props: IProps) {
		super(props);
		this.state = {
			hasMoreToLoad: true,
			collections: [],
			element: document.getElementById("content") ?? document.body,
		};

		this.fetchCollections = this.fetchCollections.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<InfinitScrollVertical scrollable={this.state.element} onNext={this.fetchCollections} triggerOnRestPixels={16}>
				<CollectionList
					collections={this.state.collections}
					editable={false}
					onlyOnchainCollections={true}
					originPath={CollectionOriginPath.MINT}
				/>
			</InfinitScrollVertical>
		);
	}

	public override async componentDidMount(): Promise<void> {
		const element = document.getElementById("content");
		if (!element) return;
		this.setState({ element });
	}

	private async fetchCollections(pendingScroll: IPendingScroll): Promise<void> {
		if (!this.state.hasMoreToLoad) {
			pendingScroll?.reject();
			return;
		}
		const collections = await Collection.getInstance().getAll(
			{ status: CollectionStatus.ONCHAIN },
			{
				notDisplayed: null,
			},
			{
				pageSize: CollectionsOnSale.PAGE_SIZE,
				page: Math.ceil(this.state.collections.length / CollectionsOnSale.PAGE_SIZE) + 1,
			},
			{ publishDate: "desc" },
		);

		const collectionToDisplay = collections.data.filter((collection) => collection.appCollectionAssets.length > 0);

		this.setState(
			{
				collections: [...this.state.collections, ...collectionToDisplay],
				hasMoreToLoad: !(collections.data.length < CollectionsOnSale.PAGE_SIZE),
			},
			() => {
				pendingScroll.resolve();
			},
		);
	}
}
