import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	label: string;
	value: string | JSX.Element;
};
type IState = {};

export default class Row extends React.Component<IProps, IState> {
	override render() {
		return (
			<div className={classes["root"]}>
				<label>{this.props.label}</label>
				<p>{this.props.value}</p>
			</div>
		);
	}
}
