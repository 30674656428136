import { ColorMode, DAppClientOptions, NetworkType, PermissionScope, RequestPermissionInput, SigningType } from "@airgap/beacon-sdk";
import { TezosToolkit } from "@taquito/taquito";
import Config from "Configs/Config";
import { EventEmitter } from "events";
import IWalletInterface, { IWalletData } from "Services/Wallet/IWalletInterface";
import ThemeMode, { EThemeModeType } from "Stores/ThemeMode";
import BigNumber from "Services/BigNumber";
import BigNumberJs from "bignumber.js";
import { Web3Auth } from "@web3auth/web3auth";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

// import * as tezosCrypto from "@tezos-core-tools/crypto-utils";
// import { SafeEventEmitterProvider } from "@web3auth/base";
// import { hex2buf } from "@taquito/utils";
// import { InMemorySigner } from "@taquito/signer";

export default class Web3auth implements IWalletInterface {
	private static wallet: Web3Auth;
	private removeEvents = () => {};

	private walletData: IWalletData | null = null;
	private readonly event = new EventEmitter();
	private web3auth = Web3auth.newWeb3authWallet();

	public getWalletData(): IWalletData {
		return (
			this.walletData ?? {
				userAddress: null,
				balance: null,
				provider: null,
			}
		);
	}

	public async connect(): Promise<Web3auth> {
		try {
			const openloginAdapter = new OpenloginAdapter({
				adapterSettings: {
					clientId: "BEXQJqC37E-KzfVnIf27FngOCJK_ylPL5ozryBP70vQ2EU2Rlh3O1sOwi2bcaK7ZpLt7NrGeB7ORXqoB9xACsS4",
					network: "testnet",
					uxMode: "popup",
				},
			});
			this.web3auth.configureAdapter(openloginAdapter);

			await this.web3auth.initModal();

			// const web3authProvider = this.web3auth.connect(); // web3auth.provider

			// const provider = this.walletData?.provider ?? new TezosToolkit(Config.getInstance().get().blockchain.tezos.rpc);

			// const instance = await this.web3auth.getUserInfo(); // web3auth instance
		} catch (err) {
			console.error(err);
		}

		return this;
	}

	public connectTo(walletName: string): Promise<any> {
		throw new Error("Not supported");
	}

	public async disconnect(): Promise<void> {
		try {
			if (!this.web3auth) {
				return;
			}
			this.web3auth.clearCache();
			await this.web3auth.logout();
			this.walletData = null;
			this.changed(null);
			this.removeEvents();
			return;
		} catch (e) {
			console.warn(e);
		}
	}

	public onChange(callback: (beaconWalletData: IWalletData) => void): () => void {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public autoConnect(): void {
		// const storage = localStorage.getItem("web3:active-account");
		// if (storage && storage !== "undefined") {
		// 	this.connect();
		// }
	}

	private async changed(provider: TezosToolkit | null) {
		const userAddress: string | null = (await provider?.wallet.pkh()) ?? null;
		const bigNumber: BigNumberJs | null = userAddress ? (await provider?.tz.getBalance(userAddress)) ?? null : null;
		const balance = bigNumber ? new BigNumber(bigNumber) : null;
		const beaconEvent: IWalletData = {
			userAddress: userAddress,
			balance: balance,
			provider: provider,
		};
		this.walletData = beaconEvent;
		this.event.emit("change", beaconEvent);
	}

	private static newWeb3authWallet() {
		const wallet = new Web3Auth({
			clientId: "BEXQJqC37E-KzfVnIf27FngOCJK_ylPL5ozryBP70vQ2EU2Rlh3O1sOwi2bcaK7ZpLt7NrGeB7ORXqoB9xACsS4", // get it from Web3Auth Dashboard
			chainConfig: {
				chainNamespace: "other", // for all non EVM and SOLANA chains, use "other"
				rpcTarget: "https://rpc.tzbeta.net/",
				displayName: "Tezos",
				blockExplorer: "https://tzstats.com",
				ticker: "XTZ",
				tickerName: "Tezos",
			},
		});
		return wallet;
	}

	private async getWeb3authWallet() {
		if (Web3auth.wallet) return Web3auth.wallet;
		Web3auth.wallet = await Web3auth.newWeb3authWallet();
		return Web3auth.wallet;
	}

	// Lit
	private initEvents(instance: any | null, provider: TezosToolkit | null): void {
		this.removeEvents();
	}

	private static getPermissions(): RequestPermissionInput {
		return {
			network: {
				type: Config.getInstance().get().blockchain.tezos.network as NetworkType,
			},
			scopes: [PermissionScope.OPERATION_REQUEST, PermissionScope.SIGN],
		};
	}

	private static getWalletOptions(): DAppClientOptions {
		return {
			name: Config.getInstance().get().app,
			colorMode: ThemeMode.getInstance().type === EThemeModeType.DARK ? ColorMode.DARK : ColorMode.LIGHT,
			preferredNetwork: Config.getInstance().get().blockchain.tezos.network as NetworkType,
		};
	}

	public async signMessage(message: string): Promise<string> {
		try {
			if (!this.getWalletData().userAddress) {
				Promise.reject("User connected");
			}

			const signedMessage = await this.getWalletData().provider.wallet.walletProvider.client.requestSignPayload({
				signingType: SigningType.MICHELINE,
				payload: message,
			});

			return signedMessage.signature;
		} catch (err) {
			return Promise.reject(err);
		}
	}
}
