import UserLike, { LikeableObject } from "Api/Factory/AppUserLike/AUserLike";
import UserLikeUsers from "Api/Factory/AppUserLike/UserLikeUser";
import { IUser } from "Entities/AuthFactory/user";
import ALikeStore from "./ALike";

export default class UsersLikeStore extends ALikeStore<IUser> {
	private static ctx: UsersLikeStore;

	private constructor() {
		super();
		UsersLikeStore.ctx = this;
	}

	public static getInstance() {
		if (!UsersLikeStore.ctx) new this();
		return UsersLikeStore.ctx;
	}

	protected getHttpService(): UserLike<LikeableObject> {
		return UserLikeUsers.getInstance();
	}
}
