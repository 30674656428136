import Module from "Components/Materials/Module";
import { IUser } from "Entities/AuthFactory/user";
import jwt, { JwtPayload } from "jsonwebtoken";
import TzContract from "Stores/Contract/TzContract";
import JwtStore, { IJwtToken } from "Stores/JwtStore";
import AskVerifyingEmailPopupStore from "Stores/PopupStore";
import RouterLocation from "Stores/RouterLocation";
import SearchResultsStatus from "Stores/SearchResultsStatus";
import TopMenuStatus, { EOpeningState } from "Stores/TopMenuStatus";
import TopSearchBarStatus from "Stores/TopSearchBar";
import TopSearchResultsStatus from "Stores/TopSearchResultsStatus";
import UserStore from "Stores/UserStore";
import Wallet from "Stores/Wallet/Wallet";
import { IWalletData } from "./Wallet/IWalletInterface";
import AppChainSync from "Api/Factory/AppChainSync/AppChainSync";
export default class StoreWorflow {
	private static instance: StoreWorflow;

	private constructor() {
		StoreWorflow.instance = this;
		this.initEvents();
	}

	public static getInstance() {
		return this.instance ?? new this();
	}

	public closeOnTopLayouts() {
		SearchResultsStatus.getInstance("global").close();
		TopSearchResultsStatus.getInstance().close();
		TopMenuStatus.getInstance().close();
		TopSearchBarStatus.getInstance().close();
	}

	private initEvents() {
		this.onTopMenuOpened();
		this.onTopSearchBarOpened();
		this.onWalletChange();
		this.onUserChange();
		this.onJwtTokenChange();
		this.onRouterLocationChange();
	}

	private onTopMenuOpened() {
		TopMenuStatus.getInstance().onSwitch((type) => {
			if (type === EOpeningState.OPENED) {
				TopSearchBarStatus.getInstance().close();
			}
		});
	}

	private onTopSearchBarOpened() {
		TopSearchBarStatus.getInstance().onSwitch((type) => {
			if (type === EOpeningState.OPENED) {
				TopMenuStatus.getInstance().close();
			}
		});
	}

	private async getContractAdresses() {
		const appchainSync = await AppChainSync.getInstance().findOne();
		return appchainSync;
	}

	private async onWalletChange() {
		Wallet.getInstance().onChange(this.refreshingWallet.bind(this));
	}

	private async refreshingWallet(walletData: IWalletData) {
		const appchainSync = await this.getContractAdresses();
		const token = JwtStore.getInstance().accessToken;
		if (!token || token === "undefined") {
			return JwtStore.getInstance().presign();
		}
		const decodedToken = jwt.decode(token) as JwtPayload;
		const tokenUserAddress = decodedToken["userAddress"];
		if (walletData.userAddress) {
			await this.onRefreshWallet(walletData, tokenUserAddress);
			await this.onChangeAccount(walletData, tokenUserAddress);
		}
		this.onSignInOrSignOutWallet(walletData, tokenUserAddress);

		TzContract.getInstance().setContractData(walletData.provider, appchainSync.factoryContractAddress, appchainSync.proxyContractAddress);
	}

	private onUserChange() {
		UserStore.getInstance().onChange(async (user) => {
			this.refreshToken(user);
			this.checkIfUserHasValidatedEmail(user);
		});
	}

	private checkIfUserHasValidatedEmail(user: IUser | null) {
		if (!user) return;
		// If there is a token in the url, it means that the user has just validated his email
		// So we don't need to show the popup
		if (user.app_user_info?.email && !user.app_user_info?.isEmailValidated && !this.hasTokenInUrl()) {
			AskVerifyingEmailPopupStore.getInstance().isOpen = true;
		}
	}

	private hasTokenInUrl() {
		const urlParams = new URLSearchParams(window.location.search);
		return Boolean(urlParams.get("token"));
	}

	private onRouterLocationChange() {
		RouterLocation.getInstance().onChange((location) => {
			const user = UserStore.getInstance().user;
			if (!user?.userAddress) return;
			if (!user.app_user_info?.acceptedPolicy) {
				const targetLocation = Module.config.pages.Register.props.path;
				if (location.pathname !== targetLocation) window.location.href = targetLocation;
			}
		});
	}

	// Get a new token if the role of the user if different of the role of the jwt token
	private refreshToken(user: IUser | null) {
		const token = JwtStore.getInstance().accessToken;
		if (!user || !token) return;
		const decodedToken = jwt.decode(token) as IJwtToken;
		if (!user.app_roles.every((userRole) => decodedToken.app_roles.some((tokenRole) => tokenRole.level === userRole.level))) {
			JwtStore.getInstance().refreshAccessToken();
		}
	}

	private onJwtTokenChange() {
		JwtStore.getInstance().onChange(async (jwtPair) => {
			const decodedToken = jwt.decode(jwtPair.accessToken) as JwtPayload;
			if (decodedToken["userAddress"]) {
				await UserStore.getInstance().loadUser();
				return;
			}
			UserStore.getInstance().setUser(null);
		});
	}

	private async onSignInOrSignOutWallet(walletData: IWalletData, tokenUserAddress: string) {
		if (walletData.userAddress) {
			if (walletData.userAddress !== tokenUserAddress) {
				const jwtPair = await JwtStore.getInstance().signin();
				if (!jwtPair?.accessToken) {
					await Wallet.getInstance().disconnect();
				}
			}
			return;
		}
		await JwtStore.getInstance().presign();
	}

	private async onRefreshWallet(walletData: IWalletData, tokenUserAddress: string) {
		if (tokenUserAddress === walletData.userAddress) {
			await UserStore.getInstance().loadUser();
		}
	}

	private async onChangeAccount(walletData: IWalletData, tokenUserAddress: string) {
		if (tokenUserAddress !== walletData.userAddress && Boolean(tokenUserAddress)) {
			await JwtStore.getInstance().presign();
		}
	}

	public disconnect() {
		Wallet.getInstance().disconnect();
		UserStore.getInstance().disconnect();
	}
}
