import { SentryService } from "./Sentry";
import TzContract from "Stores/Contract/TzContract";
import UserStore from "Stores/UserStore";
import StoreWorflow from "./StoreWorkflow";
import AppChainSync from "Api/Factory/AppChainSync/AppChainSync";

/**
 * Service that will automatically instanciate other services on load
 */
export default class AutoLoadServices {
	private static instance: AutoLoadServices;

	private constructor() {
		AutoLoadServices.instance = this;
		this.init();
	}

	public static load(): void {
		if (localStorage.getItem("cleared") !== "true") localStorage.clear();
		localStorage.setItem("cleared", "true");
		this.instance ?? new this();
	}

	private async init() {
		const appChainSync = await this.getContractAddresses();
		StoreWorflow.getInstance();
		UserStore.getInstance();
		TzContract.getInstance(appChainSync.factoryContractAddress, appChainSync.proxyContractAddress);
		SentryService.getInstance();
	}

	private async getContractAddresses() {
		const appchainSync = await AppChainSync.getInstance().findOne();
		return appchainSync;
	}
}
