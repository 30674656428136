import EventService from "Services/EventEmitter";

export interface FullScreenObj {
	showing: boolean;
	element: JSX.Element | null;
}

export default class FullscreenStore {
	private static ctx: FullscreenStore;

	private _fullScreen: FullScreenObj = {
		showing: false,
		element: null,
	};

	private readonly event = new EventService();

	private constructor() {
		FullscreenStore.ctx = this;
	}

	public static getInstance() {
		if (!FullscreenStore.ctx) new this();
		return FullscreenStore.ctx;
	}

	public get fullScreen() {
		return this._fullScreen;
	}

	public onChange(callback: (fullScreen: FullScreenObj) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public toggle() {
		const newState = !this._fullScreen.showing;
		this._fullScreen.showing = newState;
		this.event.emit("change", this._fullScreen);
	}

	public showElement(element: JSX.Element) {
		this._fullScreen = {
			element,
			showing: true,
		};

		this.event.emit("change", this._fullScreen);
	}

	public hide() {
		this._fullScreen.showing = false;
		this.event.emit("change", this._fullScreen);
	}
}
