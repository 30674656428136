import UserLike, { LikeableObject } from "Api/Factory/AppUserLike/AUserLike";
import { ILiked } from "Entities/Like";
import EventService from "Services/EventEmitter";

export default abstract class ALikeStore<T> {
	protected abstract getHttpService(): UserLike<LikeableObject>;

	private readonly event = new EventService();

	private _likes: ILiked<T>[] = [];

	public get likes(): ILiked<T>[] {
		return this._likes;
	}

	public onChange(callback: (likes: ILiked<T>[]) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public setLikes(likes: ILiked<T>[]) {
		this._likes = likes;
		this.event.emit("change", likes);
	}

	public async loadLikes(userId: number): Promise<ILiked<T>[]> {
		try {
			let likes = await this.getHttpService().getAll({ user: userId });
			this.setLikes(likes as ILiked<T>[]);
			return likes as ILiked<T>[];
		} catch (err) {
			console.error(err);
			return [];
		}
	}
}
