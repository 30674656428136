import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";

export type IAppChainSync = {
	apiUrl: string;
	createdAt: string;
	factoryContractAddress: string;
	proxyContractAddress: string;
	lastBlockSynced: number;
};

interface ISynchronizeBackupParams {
	block: number;
}

export default class AppChainSync extends BaseApiService {
	static instance: AppChainSync;
	protected static readonly baseUrl = Config.getInstance()
		.get()
		.api.authFactory.concat(`/${Config.getInstance().get().app}`)
		.concat("/app-chain-sync");

	static getInstance() {
		if (!AppChainSync.instance) {
			AppChainSync.instance = new AppChainSync();
		}

		return AppChainSync.instance;
	}

	public async findOne(): Promise<IAppChainSync> {
		const url = new URL(AppChainSync.baseUrl);
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async synchronizeBackup(params: ISynchronizeBackupParams) {
		const url = new URL(AppChainSync.baseUrl.concat("/synchronize-backup").concat(`/${params.block}`));
		
		try {
			this.getRequest(url);
		} catch (err) {
			this.onError(err);
			Promise.reject(err);
		}
	}
}
