import { IAppCreatorForm } from "Entities/CreatorForm/creatorForm";
import EventService from "Services/EventEmitter";

export default class RejectCreatorPopupStore {
	private static ctx: RejectCreatorPopupStore;

	private _opened: boolean = false;
	private _creatorForm: IAppCreatorForm | null = null;
	private readonly event = new EventService();

	private constructor() {
		RejectCreatorPopupStore.ctx = this;
	}

	public static getInstance() {
		if (!RejectCreatorPopupStore.ctx) new this();
		return RejectCreatorPopupStore.ctx;
	}

	public get opened() {
		return this._opened;
	}

	public get creatorForm() {
		return this._creatorForm;
	}

	/**
	 * @returns removelistener callback
	 */
	public onChange(callback: (opened: boolean, creatorForm: IAppCreatorForm) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public onCreatorformChange(callback: (creatorForm: IAppCreatorForm) => void) {
		this.event.on("change-creator-form", callback);
		return () => {
			this.event.off("change-creator-form", callback);
		};
	}

	public toggle() {
		this._opened = !this._opened;
		this.event.emit("change", this.opened, this.creatorForm);
		return this.opened;
	}

	public open() {
		this._opened = true;
		this.event.emit("change", this.opened, this.creatorForm);
		return this.opened;
	}

	public close() {
		this._opened = false;
		this.event.emit("change", this.opened, this.creatorForm);
		return this.opened;
	}

	public setCreatorForm(creatorForm: IAppCreatorForm) {
		this._creatorForm = creatorForm;
		this.event.emit("change-creator-form", this.creatorForm);
		this.event.emit("change", this.opened, this.creatorForm);
	}
}
