import React from "react";
import Loader from "../Loader";
import classes from "./classes.module.scss";

type IProps = {
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	variant?: "primary" | "secondary" | "tertiary" | "ghost" | "transparent" | "ghostSecond" | "rounded" | "whiteRounded" | "ghostThird" | "danger";
	sizing?: "s" | "m" | "l" | "xl" | "cube";
	fullwidth?: "true" | "false";
	iconposition?: "start" | "end";
	icon?: React.ReactNode;
	disabled?: boolean;
	type: "button" | "submit";
	action?: "reset";
	isloading: string;
	touppercase?: "true" | "false";
};

type IState = {};

export default class Button extends React.Component<IProps, IState> {
	static defaultProps: IProps = {
		variant: "primary",
		disabled: false,
		sizing: "m",
		type: "button",
		isloading: "false",
		iconposition: "start",
		fullwidth: "false",
		touppercase: "true",
	};

	public override render(): JSX.Element {
		const attributes = { ...this.props };
		delete attributes.icon;
		let icon = this.props.isloading === "true" ? <Loader /> : this.props.icon;
		return (
			<button {...attributes} onClick={this.props.onClick} className={classes["root"]} type={this.props.type} data-action={this.props.action}>
				{this.props.iconposition === "start" && icon}
				{this.props.children}
				{this.props.iconposition === "end" && icon}
			</button>
		);
	}
}
