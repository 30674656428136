import Config from "Configs/Config";
import { IAsset } from "Entities/CollectionAsset/collectionAsset";
import ALike from "Stores/UserLike/ALike";
import AssetsLikeStore from "Stores/UserLike/AssetsLike";
import UserLike, { LikeableObject } from "./AUserLike";

export default class UserLikeAssets extends UserLike<IAsset> {
	static instance: UserLikeAssets;

	static getInstance() {
		if (!UserLikeAssets.instance) {
			UserLikeAssets.instance = new UserLikeAssets();
		}

		return UserLikeAssets.instance;
	}

	protected getBaseUrl(): string {
		return`${Config.getInstance().get().api.authFactory}/${Config.getInstance().get().app}/app-user-like-assets`;
	}

	protected getStoreInstance(): ALike<LikeableObject> {
		return AssetsLikeStore.getInstance();
	}
}

