import classes from "./classes.module.scss";

//	Components
import React from "react";
import { IAppCreatorForm } from "Entities/CreatorForm/creatorForm";

import { IUser } from "Entities/AuthFactory/user";
import AddressShort from "Components/Elements/AddressShort";
import CopyClipboard from "Components/Elements/CopyClipboard";
import EditCreatorPopupStore from "Stores/EditCreatorPopupStore";
import ApproveCreatorPopupStore from "Stores/ApproveCreatorPopupStore";
import I18n from "Components/Materials/I18n";
import InfinitScrollVertical from "Components/Elements/InfinitScroll/Vertical";
import CardBox from "../../Elements/CardBox";

import { ReactComponent as EditButton } from "assets/images/icons/edit.svg";
import Button from "Components/Elements/Button";

type IProps = {
	creators: IUser[];
	onNextInfiniteScroll: () => void;
};

type IState = {
	element: HTMLElement;
};

export default class CreatorsComponent extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);

		this.state = {
			element: document.getElementById("content") ?? document.body,
		};
		this.onOpenEditCreatorPopup = this.onOpenEditCreatorPopup.bind(this);
		this.onOpenDetailPopup = this.onOpenDetailPopup.bind(this);
		this.onEditRole = this.onEditRole.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				{this.getTable()}
				{this.getResponsiveCards()}
			</div>
		);
	}

	public override async componentDidMount(): Promise<void> {
		const element = document.getElementById("content");
		if (!element) return;
		this.setState({ element });
	}

	private formatDate(date?: Date | null) {
		if (!date) return "";
		let formatDate = new Date(date).toDateString();
		formatDate = formatDate.slice(3);
		return formatDate;
	}

	private onEditRole(e: React.MouseEvent<SVGSVGElement | HTMLButtonElement, MouseEvent>, user: IUser) {
		e.stopPropagation();
		this.onOpenEditCreatorPopup(user);
	}

	private onOpenEditCreatorPopup(user: IUser) {
		EditCreatorPopupStore.getInstance().setUser(user);
		EditCreatorPopupStore.getInstance().open();
	}

	private onOpenDetailPopup(creatorForm: IAppCreatorForm | null, notShowButtons: boolean) {
		if (!creatorForm) return;
		ApproveCreatorPopupStore.getInstance().setCreatorForm(creatorForm);
		ApproveCreatorPopupStore.getInstance().setNotShowButtons(notShowButtons);
		ApproveCreatorPopupStore.getInstance().open();
	}

	private getTable() {
		return (
			<div className={classes["table-container"]}>
				<table className={classes["table"]}>
					<thead>
						<tr>
							<th className={classes["table-title"]}>{I18n.translate("pages.manage_creator.all_requests.date")}</th>
							<th className={classes["table-title"]}>{I18n.translate("pages.manage_creator.all_requests.pseudo")}</th>
							<th className={classes["table-title"]}>{I18n.translate("pages.manage_creator.all_requests.email")}</th>
							<th className={classes["table-title"]}>{I18n.translate("pages.manage_creator.all_requests.role")}</th>
							<th className={classes["table-title"]}>{I18n.translate("pages.manage_creator.all_requests.xtz_address")}</th>
							<th className={classes["table-title"]}></th>
						</tr>
					</thead>
					<InfinitScrollVertical
						scrollable={this.state.element}
						onNext={this.props.onNextInfiniteScroll}
						triggerOnRestPixels={16}
						customTag="tbody">
						<>
							{this.props.creators.map((creator) => {
								return (
									<tr
										key={creator.id}
										onClick={() => this.onOpenDetailPopup(creator.appCreatorForm, true)}
										data-has-creatorForm={!!creator.appCreatorForm}>
										<td className={[classes["table-cell"], classes["date"]].join(" ")}>
											{creator.becomeCreatorAt
												? this.formatDate(creator.becomeCreatorAt)
												: this.formatDate(creator.app_user_info?.createdAt)}
										</td>
										<td className={[classes["table-cell"], classes["pseudo"]].join(" ")}>{creator.app_user_info?.pseudo}</td>
										<td className={[classes["table-cell"], classes["email"]].join(" ")}>
											<div>{creator.app_user_info?.email}</div>
										</td>
										<td className={classes["table-cell"]}>{creator.app_user_info?.role}</td>
										<td className={[classes["table-cell"], classes["address"]].join(" ")}>
											<CopyClipboard value={creator.userAddress ?? ""}>
												<AddressShort text={creator.userAddress ?? ""} />
											</CopyClipboard>
										</td>
										<td className={classes["table-cell"]}>{<EditButton onClick={(e) => this.onEditRole(e, creator)} />}</td>
									</tr>
								);
							})}
						</>
					</InfinitScrollVertical>
				</table>
			</div>
		);
	}

	private getResponsiveCards() {
		return (
			<div className={classes["cards"]}>
				{this.props.creators.map((creator) => {
					return (
						<CardBox
							content={{
								Date: this.formatDate(creator.becomeCreatorAt),
								Pseudo: creator.app_user_info?.pseudo!,
								Email: creator.app_user_info?.email!,
								Role: creator.app_user_info?.role!,
								"XTZ address": (
									<CopyClipboard value={creator.userAddress ?? ""}>
										<AddressShort text={creator.userAddress ?? ""} />
									</CopyClipboard>
								),
							}}
							footer={
								<Button onClick={(e) => this.onEditRole(e, creator)} fullwidth={"true"} variant={"ghost"}>
									{I18n.translate("general_text.modify")}
								</Button>
							}
						/>
					);
				})}
			</div>
		);
	}
}
