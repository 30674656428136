import Loader from "Components/Elements/Loader";
import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	text?: string | JSX.Element;
};

export default class PopUpLoader extends React.Component<IProps> {
	public override render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<Loader />
				<div className={classes["text"]}>{this.props.text && this.props.text}</div>
			</div>
		);
	}
}
