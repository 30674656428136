import { IUser } from "Entities/AuthFactory/user";
import React from "react";
import UserStore from "Stores/UserStore";

type IProps = {
	no?: boolean;
};
type IState = {
	useraddress: string | null;
};

class IsConnected extends React.Component<IProps, IState> {
	private removeOnWalletChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			useraddress: UserStore.getInstance().user?.userAddress ?? null,
		};
	}

	public override render() {
		const isConnected = !!this.state.useraddress;

		// If the no is provided, return component only if user is not connected, else return component only if user is connected
		if (this.props.no) {
			return !isConnected ? this.props.children : null;
		} else {
			return isConnected ? this.props.children : null;
		}
	}

	public override componentDidMount() {
		this.removeOnWalletChange = UserStore.getInstance().onChange((user: IUser | null) =>
			this.setState({
				useraddress: user?.userAddress ?? null,
			}),
		);
	}

	public override componentWillUnmount() {
		this.removeOnWalletChange();
	}
}

export default IsConnected;
