import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	activeDefault?: boolean;
	listener?: (active: boolean) => void;
	name?: string;
	disabled: boolean;
};

type IState = {
	active: boolean;
};

export default class ButtonSwitch extends React.Component<IProps, IState> {
	static defaultProps = {
		disabled: false,
	};
	constructor(props: IProps) {
		super(props);
		this.state = {
			active: this.props.activeDefault ?? false,
		};

		this.switch = this.switch.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<div data-active={this.state.active.toString()} className={classes["root"]} onClick={this.switch} data-disabled={this.props.disabled}>
				<input
					type="checkbox"
					checked={this.props.activeDefault ?? this.state.active}
					name={this.props.name}
					hidden
					onChange={(e) => {
						return;
					}}
				/>
				<div className={classes["on"]}>On</div>
				<div className={classes["point"]} />
				<div className={classes["off"]}>Off</div>
			</div>
		);
	}

	public override componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (prevProps.activeDefault !== this.props.activeDefault) {
			this.setState({
				active: this.props.activeDefault!,
			});
		}
	}
	private switch() {
		if (this.props.disabled) return;
		this.setState({
			active: !this.state.active,
		});

		if (!this.props.listener) return;
		this.props.listener(!this.state.active);
	}
}
