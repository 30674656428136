import I18n from "Components/Materials/I18n";
import { CollectionStatus } from "Entities/Collection/enums/collectionStatus";
import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	collectionStatus: CollectionStatus;
};

export default class CollectionBadge extends React.Component<IProps> {
	public override render(): JSX.Element {
		const { collectionStatus } = this.props;
		return (
			<div className={classes["root"]} data-type={this.props.collectionStatus}>
				{collectionStatus === CollectionStatus.ONCHAIN ? I18n.translate("general_text.live") : I18n.translate("general_text.draft")}
			</div>
		);
	}
}

