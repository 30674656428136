import React from "react";
import SignInButton from "../SignInButton";
import Logo from "../Logo";
import ThemeModeSwitcher from "../ThemeModeSwitcher";
import Module from "../Module";
import classes from "./classes.module.scss";
import NavLinkElement from "../../Elements/NavLinkElement";

import { ReactComponent as HomeIcon } from "assets/images/icons/home.svg";
import { ReactComponent as MintIcon } from "assets/images/icons/mint.svg";
import { ReactComponent as ProfileIcon } from "assets/images/icons/profile.svg";
import { ReactComponent as CollectionIcon } from "assets/images/icons/collection.svg";
import { ReactComponent as ToolsIcon } from "assets/images/icons/tools.svg";
import { ReactComponent as MinteedLogoDark } from "assets/images/logos/logo-responsiv.svg";
import { ReactComponent as MinteedLogoLight } from "assets/images/logos/logo-responsiv-light.svg";
import I18n from "../I18n";
import { HashLink } from "react-router-hash-link";
import TopMenuStatus, { EOpeningState } from "Stores/TopMenuStatus";
import WindowStore from "Stores/WindowStore";
import LogoutButton from "../LogoutButton";
import IsConnected from "../IsConnected";
import ProfileMenu from "../SideMenu/ProfileMenu";
import Rules, { RulesMode } from "../Rules";
import { AppRuleActions, AppRuleNames } from "Entities/AuthFactory/rule";
import Button from "Components/Elements/Button";
import { NavigateFunction, useNavigate } from "react-router-dom";
import UserStore from "Stores/UserStore";
import { UserRole } from "Entities/AuthFactory/userInfos";
import ThemeMode, { EThemeModeType } from "Stores/ThemeMode";
import AboutUsPopupStore from "Stores/AboutUsPopupStore";
import AboutUs from "Components/Elements/AboutUs";
import { ReactComponent as BurgerIcon } from "assets/images/icons/menu-burger.svg";
import { ReactComponent as CrossIcon } from "assets/images/icons/menu-cross.svg";

type IState = {
	status: EOpeningState;
	open: 1 | 0 | -1;
	userAddress?: string | null;
	theme: EThemeModeType;
};

type IProps = {
	isMenuDisabled?: boolean;
};

type IPropsClass = IProps & {
	navigate: NavigateFunction;
};

class TopMenuClass extends React.Component<IPropsClass, IState> {
	private removeOnSwitch = () => {};
	private onScrollYDirectionChange = () => {};
	private removeUserOnChange = () => {};
	private removeThemeModeOnSwitch = () => {};

	constructor(props: IPropsClass) {
		super(props);
		this.state = {
			open: 0,
			status: TopMenuStatus.getInstance().status,
			userAddress: UserStore.getInstance().user?.userAddress ?? "",
			theme: ThemeMode.getInstance().type,
		};

		this.updateStatus = this.updateStatus.bind(this);
		this.updateTheme = this.updateTheme.bind(this);
		this.showModalAboutUsPopup = this.showModalAboutUsPopup.bind(this);
		this.redirectToBecomeCreatorPage = this.redirectToBecomeCreatorPage.bind(this);
		this.toggleMenuStatus = this.toggleMenuStatus.bind(this);
	}

	public override render(): JSX.Element {
		const pageConfig = Module.config.pages;
		return (
			<>
				{this.state.status === EOpeningState.OPENED && <div className={classes["background"]} onClick={this.toggleMenuStatus} />}
				<header className={classes["root"]} data-open={this.state.open}>
					<div className={classes["top-menu"]}>
						<div className={classes["burger"]}>
							<Module from={Module.config.Logo}>
								<HashLink className={classes["logo"]} to={this.props.isMenuDisabled ? "" : Module.config.Logo.props.path}>
									{this.getLogo()}
								</HashLink>
							</Module>

							<div className={classes["burger-menu"]}>
								<BurgerIcon onClick={this.toggleMenuStatus} className={classes["burger-icon"]} />
							</div>
						</div>

						<div className={classes["side-menu"]} data-top-menu-status={this.state.status}>
							<div className={classes["side-menu-content"]}>
								<div className={classes["top-container"]}>
									<div className={classes["top-menu-header"]}>
										<Module from={Module.config.Logo}>
											<HashLink className={classes["logo"]} to={this.props.isMenuDisabled ? "" : Module.config.Logo.props.path}>
												<Logo />
											</HashLink>
										</Module>
										<CrossIcon onClick={this.toggleMenuStatus} className={classes["close-icon"]} />
									</div>
								</div>
								<header>
									<IsConnected>
										<ProfileMenu isMenuDisabled={this.props.isMenuDisabled} />
									</IsConnected>

									<IsConnected no>
										<SignInButton />
									</IsConnected>
								</header>

								<nav>
									<Module from={pageConfig.Home}>
										<NavLinkElement
											title={<I18n map="pages_title.home" />}
											to={pageConfig.Home.props.path}
											icon={<HomeIcon />}
											isDisabled={this.props.isMenuDisabled}
										/>
									</Module>

									<Module from={pageConfig.Mint}>
										<NavLinkElement
											title={<I18n map="pages_title.mint" />}
											to={pageConfig.Mint.props.path}
											icon={<MintIcon />}
											isDisabled={this.props.isMenuDisabled}
										/>
									</Module>

									<Module from={pageConfig.Profile}>
										{UserStore.getInstance().user?.app_user_info?.role !== UserRole.ADMIN && (
											<IsConnected>
												<NavLinkElement
													title={<I18n map="pages_title.profile" />}
													to={pageConfig.Profile.props.path.replace(":address", this.state.userAddress ?? "")}
													icon={<ProfileIcon />}
													comingSoon={!pageConfig.Profile.enabled}
													isDisabled={this.props.isMenuDisabled}
												/>
											</IsConnected>
										)}
									</Module>

									<Module from={pageConfig.MyCollections}>
										<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]}>
											<NavLinkElement
												title={<I18n map="pages_title.my-collections" />}
												to={pageConfig.MyCollections.props.path}
												icon={<CollectionIcon />}
												isDisabled={this.props.isMenuDisabled}
											/>
										</Rules>
									</Module>

									<Module from={pageConfig.ManageCreators}>
										<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.update }]}>
											<NavLinkElement
												title={<I18n map="pages_title.whitelist" />}
												to={pageConfig.ManageCreators.props.path}
												icon={<ToolsIcon />}
												comingSoon={!pageConfig.ManageCreators.enabled}
												isDisabled={this.props.isMenuDisabled}
											/>
										</Rules>
									</Module>

									<Module from={pageConfig.CreateProfile}>
										<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.update }]}>
											<NavLinkElement
												title={<I18n map="pages_title.create_profile" />}
												to={pageConfig.CreateProfile.props.path}
												icon={<ToolsIcon />}
												comingSoon={!pageConfig.CreateProfile.enabled}
												isDisabled={this.props.isMenuDisabled}
											/>
										</Rules>
									</Module>

									<Module from={pageConfig.Profile}>
										{UserStore.getInstance().user?.app_user_info?.role === UserRole.ADMIN && (
											<IsConnected>
												<NavLinkElement
													title={<I18n map="pages_title.profile" />}
													to={pageConfig.Profile.props.path.replace(":address", this.state.userAddress ?? "")}
													icon={<ProfileIcon />}
													comingSoon={!pageConfig.Profile.enabled}
													isDisabled={this.props.isMenuDisabled}
												/>
											</IsConnected>
										)}
									</Module>

									<Module from={pageConfig.Tools}>
										<NavLinkElement
											title={<I18n map="pages_title.tools" />}
											to={pageConfig.Tools.props.path}
											icon={<ToolsIcon />}
											comingSoon={!pageConfig.Tools.enabled}
											isDisabled={this.props.isMenuDisabled}
										/>
									</Module>
								</nav>

								<footer>
									{!this.props.isMenuDisabled && (
										<IsConnected>
											<Module from={pageConfig.MyCollections}>
												<Rules
													mode={RulesMode.NECESSARY}
													rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]}>
													<div className={classes["footer-button-container"]}>
														<Button
															variant="rounded"
															onClick={() => this.props.navigate(Module.config.pages.CreateCollection.props.path)}>
															<I18n map="components.header_menu.create" />
														</Button>
													</div>
												</Rules>
											</Module>

											<Rules
												mode={RulesMode.NECESSARY}
												rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]}
												no>
												<div className={classes["footer-button-container"]}>
													<Button variant="rounded" onClick={this.redirectToBecomeCreatorPage}>
														<I18n map="components.header_menu.become_creator" />
													</Button>
												</div>
											</Rules>
										</IsConnected>
									)}

									<div className={classes["footer-icons-action"]}>
										<ThemeModeSwitcher variant="secondary" />
										<LogoutButton />
									</div>
									<div className={classes["aboutUs"]}>
										<AboutUs onClick={this.showModalAboutUsPopup} />
									</div>
								</footer>
							</div>
						</div>
					</div>
				</header>
			</>
		);
	}

	public override componentDidMount() {
		this.removeThemeModeOnSwitch = ThemeMode.getInstance().onSwitch(this.updateTheme);
		this.onScrollYDirectionChange = WindowStore.getInstance().onScrollYDirectionChange((scrollYDirection) => this.visibility(scrollYDirection));
		this.removeOnSwitch = TopMenuStatus.getInstance().onSwitch(this.updateStatus);
		this.removeUserOnChange = UserStore.getInstance().onChange((user) => {
			this.setState({
				userAddress: user?.userAddress,
			});
		});
	}

	public override componentWillUnmount() {
		this.removeOnSwitch();
		this.removeThemeModeOnSwitch();
		this.removeUserOnChange();
		this.onScrollYDirectionChange();
	}

	private toggleMenuStatus() {
		TopMenuStatus.getInstance().toggle();
	}

	private redirectToBecomeCreatorPage() {
		this.props.navigate(Module.config.pages.BecomeCreator.props.path);
	}

	private updateStatus(status: EOpeningState) {
		this.setState({
			status,
		});
	}

	private visibility(scrollYDirection: number) {
		let open: IState["open"] = 1;
		if (window.scrollY > 50 && scrollYDirection < 0 && Math.abs(scrollYDirection) > 8) {
			open = -1;
		}

		if (open !== this.state.open) this.setState({ open });
	}

	private updateTheme(theme: EThemeModeType): void {
		this.setState({
			theme,
		});
	}

	private getLogo() {
		if (this.state.theme === EThemeModeType.DARK) return <MinteedLogoDark />;
		return <MinteedLogoLight />;
	}

	private showModalAboutUsPopup() {
		AboutUsPopupStore.getInstance().open();
	}
}

export default function TopMenu(props: IProps) {
	const navigate = useNavigate();
	return <TopMenuClass navigate={navigate} {...props} />;
}
