import EventService from "Services/EventEmitter";

export enum EOpeningState {
	REDUCED = "reduced",
	OPENED = "opened",
}

export default class SideMenuStatus {
	private static ctx: SideMenuStatus;

	private _status: EOpeningState = (localStorage.getItem("side-menu-status") as EOpeningState) ?? EOpeningState.REDUCED;
	private readonly event = new EventService();

	private constructor() {
		SideMenuStatus.ctx = this;
		this.switch(this.status);
	}

	public static getInstance() {
		if (!SideMenuStatus.ctx) new this();
		return SideMenuStatus.ctx;
	}

	public get status() {
		return this._status;
	}

	/**
	 * @returns removelistener callback
	 */
	public onSwitch(callback: (type: EOpeningState) => void) {
		this.event.on("switch", callback);
		return () => {
			this.event.off("switch", callback);
		};
	}

	public toggle() {
		if (this.status !== EOpeningState.OPENED) {
			this.switch(EOpeningState.OPENED);
			return EOpeningState.OPENED;
		}
		this.switch(EOpeningState.REDUCED);
		return EOpeningState.REDUCED;
	}

	public open() {
		this.switch(EOpeningState.OPENED);
	}

	public close() {
		this.switch(EOpeningState.REDUCED);
	}

	private switch(type: EOpeningState) {
		if (type === this.status) return;
		this._status = type;
		localStorage.setItem("side-menu-status", this._status);
		this.event.emit("switch", this._status);
	}
}

