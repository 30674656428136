import EventService from "Services/EventEmitter";

export default class AskVerifyingEmailPopupStore {
	private static ctx: AskVerifyingEmailPopupStore;
	private readonly event = new EventService();
	private _isOpen: boolean = false;
	private _dontShowAgain: boolean = false;

	public static getInstance() {
		if (!AskVerifyingEmailPopupStore.ctx) {
			AskVerifyingEmailPopupStore.ctx = new AskVerifyingEmailPopupStore();
		}

		return AskVerifyingEmailPopupStore.ctx;
	}

	public set isOpen(isOpen: boolean) {
		this._isOpen = isOpen;
		this.event.emit("change", this._isOpen);
	}

	public get isOpen() {
		return this._isOpen;
	}

	public set dontShowAgain(dontShowAgain: boolean) {
		this._dontShowAgain = dontShowAgain;
	}

	public get dontShowAgain() {
		return this._dontShowAgain;
	}

	public onChange(callback: (isOpen: boolean) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off(" change", callback);
		};
	}
}
