import Config from "Configs/Config";
import { JwtPair } from "Entities/AuthFactory/jwtPair";
import JwtStore from "Stores/JwtStore";

export enum ContentType {
	JSON = "application/json",
	FORM_DATA = "multipart/form-data;",
}

export enum EHTTPMethod {
	GET = "GET",
	POST = "POST",
	PUT = "PUT",
	DELETE = "DELETE",
}

export default abstract class BaseApiService {
	private readonly jwtStore = JwtStore.getInstance();
	protected readonly config = Config.getInstance();
	protected readonly rootUrl = Config.getInstance().get().api.authFactory;

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected constructor() {}

	protected buildHeaders(contentType: ContentType) {
		const headers = new Headers();

		if (contentType === ContentType.JSON) {
			headers.set("Content-Type", contentType);
		}

		const token = this.jwtStore.accessToken;

		if (token) {
			headers.set("auth-token", token);
		}

		return headers;
	}

	protected buildBody(body: { [key: string]: any }): string {
		return JSON.stringify(body);
	}

	protected async getRequest<T>(url: URL): Promise<any> {
		const request = async () =>
			await fetch(url, {
				method: EHTTPMethod.GET,
				headers: this.buildHeaders(ContentType.JSON),
			});

		return this.sendRequest<T>(request);
	}

	protected async postRequest<T>(url: URL, body: { [key: string]: any } = {}): Promise<any> {
		const request = async () =>
			await fetch(url, {
				method: EHTTPMethod.POST,
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody(body),
			});

		return this.sendRequest<T>(request);
	}

	protected async postFormDataRequest<T>(url: URL, body: FormData): Promise<any> {
		const request = async () =>
			await fetch(url, {
				method: EHTTPMethod.POST,
				headers: this.buildHeaders(ContentType.FORM_DATA),
				body,
			});

		return this.sendRequest<T>(request);
	}

	protected async putRequest<T>(url: URL, body: { [key: string]: any } = {}): Promise<any> {
		const request = async () =>
			await fetch(url, {
				method: EHTTPMethod.PUT,
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody(body),
			});

		return this.sendRequest<T>(request);
	}

	protected async putFormDataRequest<T>(url: URL, body: FormData): Promise<any> {
		const request = async () =>
			await fetch(url, {
				method: EHTTPMethod.PUT,
				headers: this.buildHeaders(ContentType.FORM_DATA),
				body,
			});

		return this.sendRequest<T>(request);
	}

	protected async deleteRequest<T>(url: URL, body: { [key: string]: any } = {}): Promise<any> {
		const request = async () =>
			await fetch(url, {
				method: EHTTPMethod.DELETE,
				headers: this.buildHeaders(ContentType.JSON),
				body: this.buildBody(body),
			});

		return this.sendRequest<T>(request);
	}

	private async sendRequest<T>(request: () => Promise<Response>): Promise<T> {
		const response = await request();
		return this.processResponse<T>(response, request);
	}

	protected async processResponse<T>(response: Response, request: () => Promise<Response>): Promise<T> {
		let responseJson: any | null;
		try {
			responseJson = await response.json();
		} catch (err: unknown) {
			responseJson = null;
		}
		if (responseJson?.error?.name === "TokenExpiredError" || responseJson.error?.name === "JsonWebTokenError") {
			try {
				await this.refreshCurrentToken();

				const retryRequestResponse = await request();

				let retryRequestResponseJson: any | null;
				try {
					retryRequestResponseJson = await retryRequestResponse.json();
				} catch (err: unknown) {
					retryRequestResponseJson = null;
				}

				if (!retryRequestResponse.ok) {
					return Promise.reject(retryRequestResponse);
				}

				return retryRequestResponseJson as T;
			} catch (err: unknown) {
				const jwtPair = await this.baseApiServicePresign();
				this.jwtStore.setJwtPair(jwtPair);
			}
		} else if (response.status === 401) {
			const jwtPair = await this.baseApiServicePresign();
			this.jwtStore.setJwtPair(jwtPair);
		}

		if (!response.ok) {
			return Promise.reject(responseJson);
		}

		return responseJson as T;
	}

	protected onError(error: unknown) {
		console.error(error);
	}

	private async baseApiServicePresign(): Promise<JwtPair> {
		const url = new URL(Config.getInstance().get().api.authFactory.concat("/app-auth/", Config.getInstance().get().app.concat("/presign")));
		try {
			return this.postRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async refreshCurrentToken() {
		const response = await fetch(this.rootUrl.concat("/app-auth/").concat(this.config.get().app).concat("/refreshToken"), {
			method: "POST",
			headers: this.buildHeaders(ContentType.JSON),
			body: this.buildBody({
				accessToken: this.jwtStore.accessToken,
				refreshToken: this.jwtStore.refreshToken,
			}),
		});

		if (!response.ok) {
			return Promise.reject(response);
		}

		const { jwtPair } = (await response.json()) as { jwtPair: JwtPair };
		this.jwtStore.setJwtPair(jwtPair);
		return;
	}
}

export interface IResponse {
	http_status: number;
}
