import I18n from "Components/Materials/I18n";
import React from "react";
import Toasts from "Stores/Toasts";
import classes from "./classes.module.scss";

type IProps = {
	onClick?: () => void;
	value: string;
};
type IState = {};

export default class CopyClipboard extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<span className={classes["root"]} onClick={this.onClick}>
				{this.props.children}
			</span>
		);
	}

	private async onClick(e: React.MouseEvent<HTMLSpanElement>) {
		e.preventDefault();
		e.stopPropagation();
		await navigator.clipboard.writeText(this.props.value);
		Toasts.getInstance().open({
			title: <I18n map="general_text.copy_title" />,
			text: <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{this.props.value}</p>,
			time: 2000,
		});
	}
}
