import { ITag } from "../Tag/tag";

export enum UserRole {
	COLLECTOR = "Collector",
	CREATOR = "Creator",
	ADMIN = "Admin",
	MODERATOR = "Moderator"
}

export enum EHeaderColor {
	LAPSIS_BLUE = "Lapis Blue",
	ORION_BLUE = "Orion Blue",
	META_RED = "Meta Red",
	META_VIOLET = "Meta Violet",
}

export interface IGeneralInformations {
	pseudo: string;
	profilePicture: string;
	bannerPicture: string;
	discord: string;
	telegram: string;
	medium: string;
	twitter: string;
	instagram: string;
	facebook: string;
	website: string;
	tiktok: string;
	youtube: string;
	description: string;
	tagsDefine: ITag[];
	tagsFavorite: ITag[];
	createdAt: Date;
	headerColor: EHeaderColor;
	receivedEmail: boolean;
	acceptedPolicy: boolean;
	isEmailValidated: boolean;
}

/**
 * The required informations for a physical person
 */
export interface IPhysicalUserInformations {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	address: string;
	city: string;
	zipCode: string;
	country: string;
	countryTaxResidence: string;
	taxNumber: string;
}

export interface ICompanyContactInformations {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	address: string;
	city: string;
	zipCode: string;
	country: string;
	taxNumber: string;
}

/**
 * The required informations for a moral person, aka company informations
 */
export interface IMoralUserInformations {
	companyName: string;
	companyLegalStatus: string;
	companyRegistrationDate: string;
	companyRegistrationCity: string;
	companyRegistrationNumber: string;
}

export interface IVatInformations {
	vatTaxNumber: string;
	vatNumber: string;
	vatCountry: string;
}

export interface IActivityInformations {
	activityName: string;
	activityCountry: string;
}

export interface ILegalRepresentativeInformations {
	legalRepresentativeLastNameFirstName: string;
	legalRepresentativeBirthDate: string;
	legalRepresentativeBirthLocation: string;
	legalRepresentativeNationality: string;
}

export interface IBeneficiaryInformations {
	lastNameFirstName: string;
	birthDate: string;
	birthLocation: string;
	nationality: string;
	taxNumber: string;
	address: string;
	city: string;
	zipCode: string;
	country: string;
	nature: string;
}

/**
 * A model that mirrors the AppUserInfos database from the backend
 */
export interface IUserInfos
	extends IGeneralInformations,
		ILegalRepresentativeInformations,
		IActivityInformations,
		IVatInformations,
		IMoralUserInformations,
		IPhysicalUserInformations {
	beneficiaries: Partial<IBeneficiaryInformations>[];
	role: UserRole;
}

/**
 * A somewhat partial user with optional fields except for the
 * mandatory fields we cannot miss.
 */
export interface IPartialUserInfos extends Partial<IUserInfos> {
	role: UserRole;
}

export interface ICompanyInformations
	extends IMoralUserInformations,
		ICompanyContactInformations,
		IVatInformations,
		IActivityInformations,
		ILegalRepresentativeInformations {
	beneficiaries: IBeneficiaryInformations[];
}
