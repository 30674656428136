import EventService from "Services/EventEmitter";

export default class AboutUsPopupStore {
	private static ctx: AboutUsPopupStore;

	private _opened: boolean = false;
	private readonly event = new EventService();

	private constructor() {
		AboutUsPopupStore.ctx = this;
	}

	public static getInstance() {
		if (!AboutUsPopupStore.ctx) new this();
		return AboutUsPopupStore.ctx;
	}

	public get opened() {
		return this._opened;
	}

	/**
	 * @returns removelistener callback
	 */
	public onChange(callback: (opened: boolean) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public toggle() {
		this._opened = !this._opened;
		this.event.emit("change", this.opened);
		return this.opened;
	}

	public open() {
		this._opened = true;
		this.event.emit("change", this.opened);
		return this.opened;
	}

	public close() {
		this._opened = false;
		this.event.emit("change", this.opened);
		return this.opened;
	}
}
