import { IUser } from "Entities/AuthFactory/user";
import EventService from "Services/EventEmitter";

export default class EditCreatorPopupStore {
	private static ctx: EditCreatorPopupStore;

	private _opened: boolean = false;
	private _user: IUser | null = null;
	private readonly event = new EventService();

	private constructor() {
		EditCreatorPopupStore.ctx = this;
	}

	public static getInstance() {
		if (!EditCreatorPopupStore.ctx) new this();
		return EditCreatorPopupStore.ctx;
	}

	public get user() {
		return this._user;
	}

	public get opened() {
		return this._opened;
	}

	/**
	 * @returns removelistener callback
	 */
	public onChange(callback: (opened: boolean, user: IUser) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public toggle() {
		this._opened = !this._opened;
		this.event.emit("change", this.opened, this.user);
		return this.opened;
	}

	public open() {
		this._opened = true;
		this.event.emit("change", this.opened, this.user);
		return this.opened;
	}

	public close() {
		this._opened = false;
		this.event.emit("change", this.opened, this.user);
		return this.opened;
	}

	public setUser(user: IUser) {
		this._user = user;
		this.event.emit("change", this.opened, this.user);
	}
}
