import { request } from "graphql-request";

export default abstract class BaseGraphQlService {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected constructor() {}

	protected async graphQlRequest(url: URL, query: any): Promise<any> {
		return request(url.toString(), query);
	}

	protected onError(error: unknown) {
		console.error(error);
	}
}
