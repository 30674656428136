import UserLike, { LikeableObject } from "Api/Factory/AppUserLike/AUserLike";
import UserLikeAssets from "Api/Factory/AppUserLike/UserLikeAsset";
import { IAsset } from "Entities/CollectionAsset/collectionAsset";
import ALikeStore from "./ALike";

export default class AssetsLikeStore extends ALikeStore<IAsset> {
	private static ctx: AssetsLikeStore;

	private constructor() {
		super();
		AssetsLikeStore.ctx = this;
	}

	public static getInstance() {
		if (!AssetsLikeStore.ctx) new this();
		return AssetsLikeStore.ctx;
	}

	protected getHttpService(): UserLike<LikeableObject> {
		return UserLikeAssets.getInstance();
	}
}
