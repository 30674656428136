import React from "react";
import Wallet from "Stores/Wallet/Wallet";
import classes from "./classes.module.scss";
import I18n from "../I18n";
import IsConnected from "../IsConnected";
import SideMenuStatus, { EOpeningState } from "Stores/SideMenuStatus";
import { ReactComponent as DisconnectIcon } from "assets/images/icons/wallet-disconect.svg";

type IState = {
	sideMenuStatus: EOpeningState;
};

type IProps = {
	fullwidth?: "true" | "false";
};

export default class LogoutButton extends React.Component<IProps, IState> {
	private removeOnChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			sideMenuStatus: SideMenuStatus.getInstance().status,
		};
	}
	public override render(): JSX.Element {
		return (
			<IsConnected>
				<div
					className={classes["root"]}
					onClick={() => {
						this.disconnectWallet();
					}}>
					{this.state.sideMenuStatus === "opened" && (
						<span>
							<I18n map="components.header_menu.logout" />
						</span>
					)}
					<DisconnectIcon />
				</div>
			</IsConnected>
		);
	}

	public override componentDidMount() {
		this.removeOnChange = SideMenuStatus.getInstance().onSwitch((type) => {
			this.setState({ sideMenuStatus: type });
		});
	}
	public override componentWillUnmount() {
		this.removeOnChange();
	}

	private async disconnectWallet() {
		Wallet.getInstance().disconnect();
	}
}

