import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	/**
	* @description  The distribution of each asset in the progress bar is displayed in order of the list. The sum of this list describes 100% of the progress bar.
	*/
	assets: { quantity: number, color: string }[];
};

type IState = {}

/**
* @description  This class returns a progress bar describing a proportional distribution of assets provided as input.
*/
export default class ProgressBar extends React.Component<IProps, IState> {

	private total: number = this.props.assets.reduce((total, asset) => total += asset.quantity, 0)

	public override render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				{this.getProgressBarConcatened()}
			</div>
		);
	}

	private getProgressBarConcatened(): JSX.Element[] {
		let concatQuantity: number = 0;
		let zIndex: number = this.props.assets.length
		return (
			this.props.assets.map((asset, i) => {
				concatQuantity += asset.quantity
				zIndex -= 1
				return (this.renderProgress(concatQuantity, asset.color, zIndex, i))
			})
		)

	}

	private renderProgress(concatQuantity: number, backgroundColor: string, zIndex: number, i: number): JSX.Element {
		const width: string = (concatQuantity * 100 / this.total).toString().concat("%")
		return (
			<div key={i} className={classes["progress"]} style={{ width, backgroundColor, zIndex }} />
		)
	}
}
