import React from "react";
import { Helmet } from "react-helmet";
import HelmetStore, { HelmetContent } from "Stores/HelmetStore";

type IProps = {};
type IState = {
	helmet: HelmetContent;
};

export default class HelmetMaterial extends React.Component<IProps, IState> {
	private removeOnChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			helmet: HelmetStore.getInstance().content,
		};
		this.handleHelmetChange = this.handleHelmetChange.bind(this);
	}

	public override render(): React.ReactNode {
		return (
			<Helmet>
				<meta property="og:title" content={this.state.helmet.title} />
				<meta property="og:description" content={this.state.helmet.description} />
				<meta property="og:image" content={this.state.helmet.image} />

				<meta property="og:type" content="website" />
				<meta property="og:url" content={this.state.helmet.url} />
				<meta property="og:title" content={this.state.helmet.title} />
				<meta property="og:description" content={this.state.helmet.description} />
				<meta property="og:image" content={this.state.helmet.image} />
				<meta name="facebook-domain-verification" content="lro06s8nk3x0hhwozhmf61dwuy6t08" />

				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content={this.state.helmet.url} />
				<meta property="twitter:title" content={this.state.helmet.title} />
				<meta property="twitter:description" content={this.state.helmet.description} />
				<meta property="twitter:image" content={this.state.helmet.image} />
			</Helmet>
		);
	}

	public override componentDidMount(): void {
		this.removeOnChange = HelmetStore.getInstance().onChange(this.handleHelmetChange);
	}

	public override componentWillUnmount(): void {
		this.removeOnChange();
	}

	private handleHelmetChange(helmet: HelmetContent): void {
		this.setState({ helmet });
	}
}
