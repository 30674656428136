import { MichelsonMap } from "@taquito/taquito";
import { char2Bytes } from "@taquito/utils";
import { ICollection } from "Entities/Collection/collection";
import { IAsset } from "Entities/CollectionAsset/collectionAsset";
import BigNumber from "Services/BigNumber";
import LightCache from "Services/LightCache";
import BaseContract from "./BaseContract";

export type IAssetContract = {
	quantity: number;
	quantity_reserved: number;
	initial_price: string;
	is_mintable: boolean;
	max_per_wallet: number;
	currency: { xTZ: null };
	salemode: { fCFS: null };
	allocations: MichelsonMap<any, any>;
	allocations_decimals: number;
	metas: MichelsonMap<any, any>;
	uuid: string;
};

export default class CollectionContract extends BaseContract {
	protected readonly collectionContractCache: LightCache;
	protected static readonly QUERY_DELAY = 8;
	protected static readonly ALLOCATIONS_DECIMALS = 4;

	constructor() {
		super();
		this.collectionContractCache = LightCache.getNewNameSpace();
	}
	public async updateCollectionMetadata(collection: ICollection) {
		
		// const storage = await this.contract.storage();
		// const content = await storage.metadata.get("contents");

		// // Parsing the actual content so it's readable in an object
		// const contentJson = JSON.parse(Buffer.from(hex2buf(content) as any, "utf8").toString("utf8"));

		// // Adding one to the last number of the version
		// const versionExploded = contentJson.version.split(".");
		// versionExploded[versionExploded.length - 1]++;

		// const collectionUrl = Config.getInstance()
		// 	.get()
		// 	.rootUrl.concat(Module.config.pages.Mint.pages.MintCollectionDetail.props.path.replace(":id", collection.id));

		const newContent = char2Bytes(collection.metadataUri);
		

		const newMeta = MichelsonMap.fromLiteral({
			"": newContent,
		});

		try {
			return await this.contract.methodsObject.changeCollectionMetadata(newMeta).send();
		} catch (err: any) {
			throw new Error(err?.message);
		}
	}
	public async getContractStorage() {
		return await this.contract.storage();
	}

	public async updateMetaData(asset: IAsset, openMint: boolean) {
		const newMetaUri = char2Bytes(asset.metadataUri);
		const newMeta = MichelsonMap.fromLiteral({
			"": newMetaUri,
		});

		const revenueSharings: { [key: string]: number } = {};

		if (asset.assetRevenueSharings) {
			asset.assetRevenueSharings.forEach((revenueSharing) => {
				revenueSharings[revenueSharing.address] = (revenueSharing.percentage / 100) * Math.pow(10, CollectionContract.ALLOCATIONS_DECIMALS);
			});
		}
		try {
			return await this.contract.methodsObject
				.updateToken({
					id: asset.tokenId,
					new_metadata: newMeta,
					new_price: asset.mintPrice,
					new_allocations: [MichelsonMap.fromLiteral(revenueSharings), CollectionContract.ALLOCATIONS_DECIMALS],
					new_is_mintable: openMint,
				})
				.send();
		} catch (err: any) {
			throw new Error(err?.message);
		}
	}

	public async generateOnChainAsset(asset: IAsset) {
		const revenueSharings: { [key: string]: number } = {};

		if (asset.assetRevenueSharings) {
			asset.assetRevenueSharings.forEach((revenueSharing) => {
				revenueSharings[revenueSharing.address] = (revenueSharing.percentage / 100) * Math.pow(10, CollectionContract.ALLOCATIONS_DECIMALS);
			});
		}

		const assetParams: IAssetContract = {
			quantity: asset.totalSupply,
			quantity_reserved: asset.amountReserved ?? 0,
			initial_price: asset.mintPrice,
			is_mintable: true,
			max_per_wallet: asset.maxMintPerWallet ?? 1,
			currency: { xTZ: null },
			salemode: { fCFS: null },
			allocations: MichelsonMap.fromLiteral(revenueSharings),
			allocations_decimals: CollectionContract.ALLOCATIONS_DECIMALS,
			metas: MichelsonMap.fromLiteral({
				"": char2Bytes(asset.metadataUri),
			}),
			uuid: asset.uuid,
		};

		try {
			return await this.contract.methodsObject.premint([assetParams]).send();
		} catch (err: any) {
			console.error(err);
			throw new Error(err?.message);
		}
	}

	public async mintAsset(assetContractKeys: number, assetQuantities: number, assetPrice: string) {
		const mintAsset: any = {};
		mintAsset[assetContractKeys] = assetQuantities;
		const targetedAsset = MichelsonMap.fromLiteral(mintAsset);
		try {
			return await this.contract.methodsObject
				.mint(targetedAsset)
				.send({ amount: BigNumber.from(assetPrice).mul(assetQuantities).formatUnits(6) });
		} catch (err: any) {
			throw new Error(err?.message);
		}
	}
}
