import Config from "Configs/Config";
import BaseApiService from "Api/BaseApiService";
import { ICollection } from "Entities/Collection/collection";
import { CollectionStatus } from "Entities/Collection/enums/collectionStatus";
import { IPaginatedResult } from "Entities/PaginatedResult";
import { IPaginatedParams } from "Entities/PaginatedParam";

interface IPostParams {
	body: FormData;
}

interface IPutParams {
	collectionId: number;
	body: FormData;
}

interface IGetParams {
	collectionId: number;
	assetFilter?: {
		notDisplayed?: Date | null;
	};
	collectionFilter?: {
		notDisplayed?: Date | null;
	};
}

interface IGetAllParams {
	owner?: {
		userAddress: string;
	};
	incoming?: boolean;
	status?: CollectionStatus;
	notDisplayed?: Date | null;
}

interface IFilterAssets {
	// assetFilter?:{
	// 	notDisplayed?: Date | null;
	// };
	notDisplayed: Date | null;
}

interface IDeleteParams {
	collectionId: number;
}

type ISortParams = {
	[key in keyof ICollection | "updatedAt" | "publishDate" | "createdAt"]?: "asc" | "desc";
};

type IBlacklistParams = {
	collectionId: number;
};
export default class Collection extends BaseApiService {
	static instance: Collection;
	protected static readonly baseUrl = `${Config.getInstance().get().api.authFactory}/${Config.getInstance().get().app}/app-collections`;

	static getInstance() {
		if (!Collection.instance) {
			Collection.instance = new Collection();
		}

		return Collection.instance;
	}

	public async getAll(
		params?: IGetAllParams,
		filter?: IFilterAssets,
		pagination?: IPaginatedParams,
		sort?: ISortParams,
	): Promise<IPaginatedResult<ICollection>> {
		const url = new URL(Collection.baseUrl);

		if (params) {
			url.searchParams.set("q", JSON.stringify(params));
		}

		if (filter) {
			url.searchParams.set("f", JSON.stringify(filter));
		}

		if (pagination) {
			url.searchParams.set("p", JSON.stringify(pagination));
		}

		if (sort) {
			url.searchParams.set("s", JSON.stringify(sort));
		}

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async get(params: IGetParams): Promise<ICollection | null> {
		const url = new URL(`${Collection.baseUrl}/${params.collectionId}`);

		if (params.collectionFilter) {
			url.searchParams.set("q", JSON.stringify(params.collectionFilter));
		}

		if (params.assetFilter) {
			url.searchParams.set("f", JSON.stringify(params.assetFilter));
		}

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async post(params: IPostParams): Promise<ICollection> {
		const url = new URL(Collection.baseUrl);

		try {
			return this.postFormDataRequest(url, params.body);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async put(params: IPutParams): Promise<ICollection> {
		const url = new URL(`${Collection.baseUrl}/${params.collectionId}`);
		try {
			return this.putFormDataRequest(url, params.body);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async delete(params: IDeleteParams): Promise<ICollection> {
		const url = new URL(`${Collection.baseUrl}/${params.collectionId}`);
		try {
			return this.deleteRequest(url, { id: params.collectionId });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async deleteCascade(params: IDeleteParams): Promise<ICollection> {
		const url = new URL(`${Collection.baseUrl}/delete-cascade/${params.collectionId}`);
		try {
			return Promise.resolve(this.deleteRequest(url, { id: params.collectionId }));
		} catch (err) {
			return Promise.reject(err);
		}
	}

	public async blacklist(params: IBlacklistParams): Promise<ICollection> {
		const url = new URL(`${Collection.baseUrl}/blacklist/${params.collectionId}`);
		try {
			return Promise.resolve(this.putRequest(url));
		} catch (err) {
			return Promise.reject(err);
		}
	}

	public async unblacklist(params: IBlacklistParams): Promise<ICollection> {
		const url = new URL(`${Collection.baseUrl}/unblacklist/${params.collectionId}`);
		try {
			return Promise.resolve(this.putRequest(url));
		} catch (err) {
			return Promise.reject(err);
		}
	}
}
