import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Config from "Configs/Config";
import UserStore from "Stores/UserStore";

export class SentryService {
	private static ctx: SentryService;

	constructor() {
		Sentry.init({
			dsn: Config.getInstance().get().sentry.dsn,
			integrations: [new BrowserTracing()],
			tracesSampleRate: 1.0,
			environment: process.env["REACT_APP_ENV_NAME"],
			enabled: Config.getInstance().get().sentry.enabled,
		});
		SentryService.ctx = this;
	}

	public static getInstance() {
		if (!SentryService.ctx) new this();
		return SentryService.ctx;
	}

	public captureException(error: Error) {
		const user = UserStore.getInstance().user;
		if (!user) {
			Sentry.captureException(error);
			return;
		}
		Sentry.withScope(function (scope) {
			scope.setUser({
				id: user?.id,
				username: user?.app_user_info?.pseudo,
				email: user?.app_user_info?.email,
			});
			Sentry.captureException(error);
		});
	}
}
