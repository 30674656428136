import classes from "./classes.module.scss";

//	Components
import Button from "Components/Elements/Button";
import I18n from "Components/Materials/I18n";
import React from "react";
import { IAppCreatorForm } from "Entities/CreatorForm/creatorForm";
import RejectCreatorPopupStore from "Stores/RejectCreatorPopupStore";
import ApproveCreatorPopupStore from "Stores/ApproveCreatorPopupStore";
import InfinitScrollVertical from "Components/Elements/InfinitScroll/Vertical";
import CardBox from "../../Elements/CardBox";

type IProps = {
	creatorForms: IAppCreatorForm[];
	onNextInfiniteScroll: () => void;
};

type IState = {
	element: HTMLElement;
};

export default class NewRequestElement extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			element: document.getElementById("content") ?? document.body,
		};
		this.onReject = this.onReject.bind(this);
		this.onApprove = this.onApprove.bind(this);
		this.onOpenRejectModal = this.onOpenRejectModal.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				{this.props.creatorForms.length > 0 ? (
					<>
						{this.getTable()} {this.getResponsiveCards()}
					</>
				) : (
					<div className={classes["no-request"]}>
						<I18n map="pages.manage_creator.all_requests.no_request" />
					</div>
				)}
			</div>
		);
	}

	public override async componentDidMount(): Promise<void> {
		const element = document.getElementById("content");
		if (!element) return;
		this.setState({ element });
	}

	private formatDate(date?: Date | null) {
		if (!date) return "";
		let formatDate = new Date(date).toDateString();
		formatDate = formatDate.slice(3);
		return formatDate;
	}

	private onApprove(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, creatorForm: IAppCreatorForm) {
		e.stopPropagation();
		this.onOpenDetailsModal(creatorForm, false);
	}

	private onReject(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, creatorForm: IAppCreatorForm) {
		e.stopPropagation();
		this.onOpenRejectModal(creatorForm);
	}

	private onOpenRejectModal(creatorForm: IAppCreatorForm) {
		RejectCreatorPopupStore.getInstance().setCreatorForm(creatorForm);
		RejectCreatorPopupStore.getInstance().open();
	}

	private onOpenDetailsModal(creatorForm: IAppCreatorForm, notShowButtons: boolean) {
		ApproveCreatorPopupStore.getInstance().setCreatorForm(creatorForm);
		ApproveCreatorPopupStore.getInstance().setNotShowButtons(notShowButtons);
		ApproveCreatorPopupStore.getInstance().open();
	}

	private getTable() {
		return (
			<div className={classes["root"]}>
				<div className={classes["table-container"]}>
					<table className={classes["table"]}>
						<thead>
							<tr>
								<th className={classes["table-title"]}>
									<I18n map="pages.manage_creator.all_requests.date" />
								</th>
								<th className={classes["table-title"]}>
									<I18n map="pages.manage_creator.all_requests.pseudo" />
								</th>
								<th className={classes["table-title"]}>
									<I18n map="pages.manage_creator.all_requests.email" />
								</th>
								<th className={classes["table-title"]}>
									<I18n map="pages.manage_creator.all_requests.inscription" />
								</th>
								<th className={classes["table-title"]}>
									<I18n map="pages.manage_creator.all_requests.validation" />
								</th>
							</tr>
						</thead>

						<InfinitScrollVertical
							scrollable={this.state.element}
							onNext={this.props.onNextInfiniteScroll}
							triggerOnRestPixels={16}
							customTag="tbody">
							<>
								{this.props.creatorForms.map((creatorForm) => {
									return (
										<tr
											key={creatorForm.id}
											onClick={() => this.onOpenDetailsModal(creatorForm, true)}
											className={classes["row"]}>
											<td className={[classes["table-cell"], classes["date"]].join(" ")}>
												{this.formatDate(creatorForm.createdAt)}
											</td>
											<td className={[classes["table-cell"], classes["pseudo"]].join(" ")}>{creatorForm.name}</td>
											<td className={[classes["table-cell"], classes["email"]].join(" ")}>
												<div>{creatorForm.email}</div>
											</td>
											<td className={classes["table-cell"]}>Creator</td>
											<td className={[classes["table-cell"], classes["button"]].join(" ")}>{this.getButtons(creatorForm)}</td>
										</tr>
									);
								})}
							</>
						</InfinitScrollVertical>
					</table>
				</div>
			</div>
		);
	}

	private getResponsiveCards() {
		return (
			<div className={classes["cards"]}>
				{this.props.creatorForms.map((creatorForm) => {
					return (
						<CardBox
							content={{
								Data: this.formatDate(creatorForm.createdAt),
								Pseudo: creatorForm.name,
								Email: creatorForm.email,
								Inscription: "Creator",
							}}
							footer={this.getButtons(creatorForm, true)}
						/>
					);
				})}
			</div>
		);
	}

	private getButtons(creatorForm: IAppCreatorForm, centeredButtons?: boolean) {
		return (
			<div className={centeredButtons ? [classes["button-container"], classes["centered"]].join(" ") : classes["button-container"]}>
				<Button variant="ghost" onClick={(e) => this.onReject(e, creatorForm)}>
					<I18n map={"pages.manage_creator.all_requests.reject"} />
				</Button>
				<Button onClick={(e) => this.onApprove(e, creatorForm)}>
					<I18n map={"pages.manage_creator.all_requests.approve"} />
				</Button>
			</div>
		);
	}
}
