import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { IUser } from "Entities/AuthFactory/user";
import { JwtPair } from "Entities/AuthFactory/jwtPair";

type IPostSignin = {
	signedMessage: string;
	message: string;
	publicKey: string;
};

interface IPostSigninResponse {
	jwtPair: JwtPair;
	user: IUser;
}

export default class Signin extends BaseApiService {
	static instance: Signin;
	protected static readonly baseUrl = Config.getInstance().get().api.authFactory.concat("/app-auth/").concat(Config.getInstance().get().app);

	static getInstance() {
		if (!Signin.instance) {
			Signin.instance = new Signin();
		}

		return Signin.instance;
	}

	public async post(params: IPostSignin): Promise<IPostSigninResponse> {
		const url = new URL(Signin.baseUrl.concat("/signin"));
		try {
			return this.postRequest(url, params);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
