import EventService from "Services/EventEmitter";

export enum EOpeningState {
	CLOSED = "closed",
	OPENED = "opened",
}

export type ISearchableData = {
	type: "Collection" | "Nft" | "Profile";
	image?: string;
	name: string;
	link?: string;
};

export type SearchBarLabelType =
	| "global"
	| "lottery"
	| "reservations"
	| "create-reservation"
	| "profile-filter"
	| "auctions"
	| "offers"
	| "sales-history"
	| "collections";

export default class SearchResultsStatus {
	private static ctx: SearchResultsStatus;

	private type;
	private historyKey: string;
	private _status: EOpeningState = EOpeningState.CLOSED;
	private _history: ISearchableData[] = [];
	private _selectedValue: ISearchableData | null = null;
	private readonly event = new EventService();

	private constructor(type: SearchBarLabelType) {
		SearchResultsStatus.ctx = this;
		this.type = type;
		this.historyKey = "search-bar-history-" + this.type;
		this.initHistory();
		this.switch(this.status);
	}

	public static getInstance(type: SearchBarLabelType) {
		if (!SearchResultsStatus.ctx) new this(type);
		return SearchResultsStatus.ctx;
	}

	public get status() {
		return this._status;
	}

	public get history() {
		return this._history;
	}

	public get selectedValue() {
		return this._selectedValue;
	}

	/**
	 * @returns removelistener callback
	 */
	public onSwitch(callback: (status: EOpeningState) => void) {
		this.event.on("switch", callback);
		return () => {
			this.event.off("switch", callback);
		};
	}

	public onSelect(callback: (history: ISearchableData[], selectedValue: ISearchableData) => void) {
		this.event.on("select", callback);
		return () => {
			this.event.off("select", callback);
		};
	}

	public toggle() {
		if (this.status === EOpeningState.CLOSED) {
			this.switch(EOpeningState.OPENED);
			return EOpeningState.OPENED;
		}
		this.switch(EOpeningState.CLOSED);
		return EOpeningState.CLOSED;
	}

	public close() {
		this.switch(EOpeningState.CLOSED);
	}

	public open() {
		this.switch(EOpeningState.OPENED);
	}

	public selectResult(selected: ISearchableData) {
		this.select(selected);
	}

	private canSwitch: boolean = true;

	public userCanSwitch(canSwitch: boolean): void {
		this.canSwitch = canSwitch;
	}

	private switch(type: EOpeningState) {
		if (this.canSwitch) {
			if (type === this.status) return;
			this._status = type;
			this.event.emit("switch", this._status);
		}
	}

	private select(selected: ISearchableData) {
		this._history = this._history.filter((element) => element !== selected);
		this._history.unshift(selected);
		this._selectedValue = selected;
		localStorage.setItem(this.historyKey, JSON.stringify(this._history));
		this.event.emit("select", this._history, this._selectedValue);
	}

	private initHistory() {
		this._history =
			((localStorage.getItem(this.historyKey)
				? JSON.parse(localStorage.getItem(this.historyKey) ?? "")
				: null) as ISearchableData[]) ?? [];
	}
}
