import React from "react";
import classes from "./classes.module.scss";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
//import PseudoPopupStore from "Stores/PseudoPopup";
import { IUser } from "Entities/AuthFactory/user";
import UserStore from "Stores/UserStore";
import { ReactComponent as ProfileIcon } from "assets/images/icons/profile.svg";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Module from "Components/Materials/Module";
import CopyClipboard from "Components/Elements/CopyClipboard";
import AddressShort from "Components/Elements/AddressShort";
type IProps = {
	isReduced?: boolean;
	isMenuDisabled?: boolean;
};
type IState = {
	user: IUser | null;
	menuOpened: boolean;
};

type IPropsClass = IProps & {
	navigate: NavigateFunction;
};
class ProfileMenuClass extends React.Component<IPropsClass, IState> {
	private removeUserOnChange = () => {};

	constructor(props: IPropsClass) {
		super(props);

		this.state = {
			user: UserStore.getInstance().user,
			menuOpened: false,
		};

		this.openMenu = this.openMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.navigateToEditProfile = this.navigateToEditProfile.bind(this);
		this.getAddress = this.getAddress.bind(this);
	}

	public override render() {
		return this.props.isReduced ? (
			<div className={classes["root"]}>
				<div className={classes["profile-icon-container"]}>
					<ProfileIcon />
				</div>
			</div>
		) : (
			<div className={classes["root"]}>
				<div className={classes["user-container"]}>
					<div className={classes["pseudo-container"]}>
						<p className={classes["pseudo"]}>
							{this.state.user?.app_user_info?.pseudo}
							{!this.props.isMenuDisabled && <EditIcon onClick={this.navigateToEditProfile} />}
						</p>
					</div>
					<CopyClipboard value={this.getAddress()}>
						<AddressShort text={this.getAddress()} copyIcon={true} />
					</CopyClipboard>
				</div>
			</div>
		);
	}

	public getAddress() {
		if (this.state.user?.userAddressDomain) {
			return this.state.user?.userAddressDomain;
		} else {
			return this.state.user?.userAddress ?? "";
		}
	}

	public override componentDidMount() {
		this.removeUserOnChange = UserStore.getInstance().onChange((user) => {
			this.setState({
				user,
			});
		});
	}

	public override componentWillUnmount() {
		this.removeUserOnChange();
	}

	private openMenu() {
		this.setState({
			menuOpened: true,
		});
	}

	private closeMenu() {
		this.setState({
			menuOpened: false,
		});
	}

	private navigateToEditProfile() {
		this.props.navigate(Module.config.pages.EditProfile.props.path);
	}
}

export default function ProfileMenu(props: IProps) {
	const navigate = useNavigate();
	return <ProfileMenuClass {...props} navigate={navigate} />;
}
