import UserLike, { LikeableObject } from "Api/Factory/AppUserLike/AUserLike";
import UserLikeCollections from "Api/Factory/AppUserLike/UserLikeCollection";
import { ICollection } from "Entities/Collection/collection";
import ALikeStore from "./ALike";

export default class CollectionsLikeStore extends ALikeStore<ICollection> {
	private static ctx: CollectionsLikeStore;

	private constructor() {
		super();
		CollectionsLikeStore.ctx = this;
	}

	public static getInstance() {
		if (!CollectionsLikeStore.ctx) new this();
		return CollectionsLikeStore.ctx;
	}

	protected getHttpService(): UserLike<LikeableObject> {
		return UserLikeCollections.getInstance();
	}
}
