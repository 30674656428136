import Config from "Configs/Config";
import { ICollection } from "Entities/Collection/collection";
import ALike from "Stores/UserLike/ALike";
import CollectionsLikeStore from "Stores/UserLike/CollectionsLike";
import UserLike, { LikeableObject } from "./AUserLike";

export default class UserLikeCollections extends UserLike<ICollection> {
	static instance: UserLikeCollections;

	static getInstance() {
		if (!UserLikeCollections.instance) {
			UserLikeCollections.instance = new UserLikeCollections();
		}

		return UserLikeCollections.instance;
	}

	protected getBaseUrl(): string {
		return`${Config.getInstance().get().api.authFactory}/${Config.getInstance().get().app}/app-user-like-collections`;
	}
	
	protected getStoreInstance(): ALike<LikeableObject> {
		return CollectionsLikeStore.getInstance();
	}
}

