import EventService from "Services/EventEmitter";
import { Location } from "history";

export default class RouterLocation {
	private static ctx: RouterLocation;

	private readonly event = new EventService();

	private _location: Location | null = null;
	private _historyLocations: (Location | null)[] = [];
	private _pushEnabled: boolean = true;

	private constructor() {
		RouterLocation.ctx = this;
	}

	public get location() {
		return this._location;
	}

	public get pushEnabled() {
		return this._pushEnabled;
	}

	public static getInstance() {
		if (!RouterLocation.ctx) new this();
		return RouterLocation.ctx;
	}

	/**
	 * @returns removelistener callback
	 */
	public onChange(callback: (location: Location) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public change(location: Location) {
		if (location.pathname === this._location?.pathname) return;
		this._location = location;
		this._pushEnabled= true;
		this.event.emit("change", location);
	}

	public push(location: Location) {
		if (location.pathname === this._location?.pathname) return;
		this._historyLocations?.push(location);
		this._location = location;
		this.event.emit("change", location);
	}

	public preSet(location: Location) {
		if (this._location) return;
		this._historyLocations?.push(location);
		this._location = location;
		this.event.emit("change", location);
	}

	public unstackHistory(): Location | null {
		if (this._historyLocations.length < 0) return null;
		this._historyLocations?.pop();
		const previousLocation = this._historyLocations[this._historyLocations.length - 1] ?? null;
		this._pushEnabled= false;
		return previousLocation;
	}
}
