import Button from "Components/Elements/Button";
import React from "react";
import { ReactComponent as WalletConnect } from "assets/images/icons/wallet-connect.svg";
import I18n from "../I18n";
import classes from "./classes.module.scss";
import { Link } from "react-router-dom";
import Module from "../Module";

type IProps = {
	isReduced: boolean;
};
type IState = {};

export default class SignInButton extends React.Component<IProps, IState> {
	static defaultProps: IProps = {
		isReduced: false,
	};

	public override render(): JSX.Element {
		return this.props.isReduced ? (
			<div className={classes["root"]}>
				<Link to={Module.config.pages.SignIn.props.path}>
					<WalletConnect />
				</Link>
			</div>
		) : (
			<Link to={Module.config.pages.SignIn.props.path} className={classes["signIn"]}>
				<Button variant="ghostSecond" sizing="m" fullwidth={"true"}>
					<I18n map="general_text.connect_wallet" />
				</Button>
			</Link>
		);
	}
}
