import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { IAppCreatorForm } from "Entities/CreatorForm/creatorForm";
import { ECreatorFormStatus } from "Entities/CreatorForm/enums/CreatorFormStatus";
import { IPaginatedParams } from "Entities/PaginatedParam";
import { IPaginatedResult } from "Entities/PaginatedResult";

interface IGetQueryString {
	q?: {};
}

interface IGetAllParams {
	status: ECreatorFormStatus;
}

export default class CreatorForm extends BaseApiService {
	static instance: CreatorForm;
	protected static readonly baseUrl = `${Config.getInstance().get().api.authFactory}/${Config.getInstance().get().app}/app-creator-form`;

	static getInstance() {
		if (!CreatorForm.instance) {
			CreatorForm.instance = new CreatorForm();
		}

		return CreatorForm.instance;
	}

	public async get(queryString: IGetQueryString): Promise<IPaginatedResult<IAppCreatorForm>> {
		const url = new URL(CreatorForm.baseUrl);
		Object.entries(queryString).forEach(([key, value]) => url.searchParams.set(key, JSON.stringify(value)));
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getAll(params: IGetAllParams, pagination?: IPaginatedParams): Promise<IPaginatedResult<IAppCreatorForm>> {
		const url = new URL(CreatorForm.baseUrl);

		if (params) {
			url.searchParams.set("q", JSON.stringify(params));
		}

		if (pagination) {
			url.searchParams.set("p", JSON.stringify(pagination));
		}

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getOneByEmail(email: string): Promise<IAppCreatorForm | null> {
		const creatorForm = await this.get({
			q: {
				email: { $eq: email },
			},
		});

		return creatorForm.data[0] ?? null;
	}

	public async post(body: { [key: string]: any }) {
		const url = new URL(CreatorForm.baseUrl);
		try {
			return this.postRequest(url, body);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async changeStatus(creatorFormId: string, body: { [key: string]: any }): Promise<IAppCreatorForm | null> {
		const url = new URL(CreatorForm.baseUrl.concat("/changeStatus/").concat(creatorFormId));
		try {
			return this.putRequest(url, body);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
