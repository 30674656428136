import { TezosToolkit } from "@taquito/taquito";
export default abstract class BaseContract {
	protected contract: any | null = null;
	protected contractAddress: string | null = null;

	protected constructor() {}

	public async init(contractAddress: string, provider: TezosToolkit) {
		await this.setContract(contractAddress, provider);
	}

	public getContract() {
		return this.contract;
	}

	private async setContract(contractAddress: string, provider: TezosToolkit) {
		this.contract = await provider.wallet.at(contractAddress);
	}
}
