import { Component } from "react";

// Assets
import { ReactComponent as Heart } from "assets/images/icons/heart.svg";
import { ReactComponent as HeartFull } from "assets/images/icons/heart-full.svg";

// Styles
import classes from "./classes.module.scss";

export enum ELikeType {
	COLLECTION = "collection",
	ASSET = "asset",
}

type IProps = {
	isLiked: boolean;
	onClick: () => void;
	likesCount: number;
	className?: string;
};
type IState = {};

export default class LikeButton extends Component<IProps, IState> {
	override render() {
		return (
			<div className={[classes["root"], this.props.className].join(" ")} onClick={this.props.onClick}>
				<div className={classes["image-container"]}>{this.props.isLiked ? <HeartFull /> : <Heart />}</div>
				<p className={classes["like-count"]}>{this.props.likesCount}</p>
			</div>
		);
	}
}
