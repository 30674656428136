import React from "react";
import classes from "./classes.module.scss";
import classNames from "classnames";
import Module from "../../Module";
import { HashLink } from "react-router-hash-link";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";

import { ReactComponent as ProfileIcon } from "assets/images/icons/profile.svg";
import { ReactComponent as MintIcon } from "assets/images/icons/mint.svg";
import { ReactComponent as CollectionIcon } from "assets/images/icons/collection.svg";
import { ReactComponent as ToolsIcon } from "assets/images/icons/tools.svg";
import { ReactComponent as MenuArrowLight } from "assets/images/icons/menu-arrow-light.svg";
import { ReactComponent as MenuArrowDark } from "assets/images/icons/menu-arrow-dark.svg";
import { ReactComponent as HomeIcon } from "assets/images/icons/home.svg";
import Logo from "../../Logo";

import { AppRuleActions, AppRuleNames } from "Entities/AuthFactory/rule";
import { UserRole } from "Entities/AuthFactory/userInfos";

import SideMenuStatus, { EOpeningState } from "Stores/SideMenuStatus";
import ThemeMode from "Stores/ThemeMode";
import UserStore from "Stores/UserStore";
import AboutUsPopupStore from "Stores/AboutUsPopupStore";
import IsConnected from "Components/Materials/IsConnected";
import ProfileMenu from "../ProfileMenu";
import SignInButton from "Components/Materials/SignInButton";
import NavLinkElement from "Components/Elements/NavLinkElement";
import I18n from "Components/Materials/I18n";
import Rules, { RulesMode } from "Components/Materials/Rules";
import AboutUs from "Components/Elements/AboutUs";
import Button from "Components/Elements/Button";
import LogoutButton from "Components/Materials/LogoutButton";
import ThemeModeSwitcher from "Components/Materials/ThemeModeSwitcher";

type IState = {
	status: EOpeningState;
	isBecomeCreatorModalOpen: boolean;
	userAddress?: string | null;
	isScrolling: boolean;
};

type IProps = {
	isMenuDisabled?: boolean;
};

type IPropsClass = IProps & {
	navigate: NavigateFunction;
};

class SideMenuOpenedClass extends React.Component<IPropsClass, IState> {
	private readonly _rootRef: React.RefObject<HTMLDivElement> = React.createRef();
	private intervalToScroll: NodeJS.Timer | null = null;

	private removeOnSwitch = () => {};
	private removeUserOnChange = () => {};

	constructor(props: IPropsClass) {
		super(props);
		this.state = {
			status: SideMenuStatus.getInstance().status,
			isBecomeCreatorModalOpen: false,
			userAddress: UserStore.getInstance().user?.userAddress ?? "",
			isScrolling: false,
		};

		this.openBecomeCreatorModal = this.openBecomeCreatorModal.bind(this);
		this.showModalAboutUsPopup = this.showModalAboutUsPopup.bind(this);
		this.onScroll = this.onScroll.bind(this);
	}

	public override render(): JSX.Element {
		const pageConfig = Module.config.pages;
		return (
			<div
				className={classNames([classes["root"], classes[this.state.status]].join(" "), {
					[classes["hide-scrollbar"]!]: !this.state.isScrolling,
				})}
				ref={this._rootRef}>
				<header>
					<Module from={Module.config.Logo}>
						<HashLink className={classes["logo"]} to={this.props.isMenuDisabled ? "" : Module.config.Logo.props.path}>
							<Logo/>
						</HashLink>
					</Module>
				</header>
				<IsConnected>
					<ProfileMenu isReduced={false} isMenuDisabled={this.props.isMenuDisabled} />
				</IsConnected>
				<IsConnected no>
					<SignInButton isReduced={false} />
				</IsConnected>
				<nav>
					{window.location.pathname === "/register"}
					<Module from={pageConfig.Home}>
						<NavLinkElement
							title={<I18n map="pages_title.home" />}
							to={pageConfig.Home.props.path}
							icon={<HomeIcon />}
							reduced={false}
							isDisabled={this.props.isMenuDisabled}
						/>
					</Module>

					<Module from={pageConfig.Mint}>
						<NavLinkElement
							title={<I18n map="pages_title.mint" />}
							to={pageConfig.Mint.props.path}
							icon={<MintIcon />}
							reduced={false}
							isDisabled={this.props.isMenuDisabled}
						/>
					</Module>

					<IsConnected>
						<Module from={pageConfig.MyCollections}>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]}>
								<NavLinkElement
									title={<I18n map="pages_title.my-collections" />}
									to={pageConfig.MyCollections.props.path}
									icon={<CollectionIcon />}
									reduced={false}
									isDisabled={this.props.isMenuDisabled}
								/>
							</Rules>
						</Module>

						<Module from={pageConfig.Profile}>
							{UserStore.getInstance().user?.app_user_info?.role !== UserRole.ADMIN && (
								<NavLinkElement
									title={<I18n map="pages_title.profile" />}
									to={pageConfig.Profile.props.path.replace(":address", this.state.userAddress ?? "")}
									icon={<ProfileIcon />}
									comingSoon={!pageConfig.Profile.enabled}
									reduced={false}
									isDisabled={this.props.isMenuDisabled}
								/>
							)}
						</Module>

						<Module from={pageConfig.ManageCreators}>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.update }]}>
								<NavLinkElement
									title={<I18n map="pages_title.whitelist" />}
									to={pageConfig.ManageCreators.props.path}
									icon={<ToolsIcon />}
									comingSoon={!pageConfig.ManageCreators.enabled}
									reduced={false}
									isDisabled={this.props.isMenuDisabled}
								/>
							</Rules>
						</Module>

						<Module from={pageConfig.CreateProfile}>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.update }]}>
								<NavLinkElement
									title={<I18n map="pages_title.create_profile" />}
									to={pageConfig.CreateProfile.props.path}
									icon={<ToolsIcon />}
									comingSoon={!pageConfig.CreateProfile.enabled}
									reduced={false}
									isDisabled={this.props.isMenuDisabled}
								/>
							</Rules>
						</Module>

						<Module from={pageConfig.Profile}>
							{UserStore.getInstance().user?.app_user_info?.role === UserRole.ADMIN && (
								<NavLinkElement
									title={<I18n map="pages_title.profile" />}
									to={pageConfig.Profile.props.path.replace(":address", this.state.userAddress ?? "")}
									icon={<ProfileIcon />}
									comingSoon={!pageConfig.Profile.enabled}
									reduced={false}
									isDisabled={this.props.isMenuDisabled}
								/>
							)}
						</Module>
					</IsConnected>

					<Module from={pageConfig.Tools}>
						<NavLinkElement
							title={<I18n map="pages_title.tools" />}
							to={pageConfig.Tools.props.path}
							icon={<ToolsIcon />}
							comingSoon={!pageConfig.Tools.enabled}
							reduced={false}
							isDisabled={this.props.isMenuDisabled}
						/>
					</Module>
				</nav>
				<footer>
					<Module from={pageConfig.CreateCollection}>
						<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]}>
							<div className={classes["footer-button-container"]}>
								<Button
									variant="rounded"
									sizing="m"
									onClick={() => this.props.navigate(Module.config.pages.CreateCollection.props.path)}>
									<I18n map="components.header_menu.create" />
								</Button>
							</div>
						</Rules>
					</Module>
					{!this.props.isMenuDisabled && (
						<IsConnected>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]} no>
								<div className={classes["footer-button-container"]}>
									<Link to={Module.config.pages.BecomeCreator.props.path}>
										<Button variant="rounded" sizing="m">
											<I18n map="components.header_menu.become_creator" />
										</Button>
									</Link>
								</div>
							</Rules>
						</IsConnected>
					)}

					<div className={classes["footer-icons-action"]}>
						<ThemeModeSwitcher variant="secondary" />
						<div className={classes["menu-arrow-container"]}>
							{ThemeMode.getInstance().type === "light" ? (
								<MenuArrowLight onClick={this.closeSideMenu} />
							) : (
								<MenuArrowDark onClick={this.closeSideMenu} />
							)}
						</div>
						<LogoutButton />
					</div>
					<div className={classes["aboutUs"]}>
						<AboutUs onClick={this.showModalAboutUsPopup} />
					</div>
				</footer>
			</div>
		);
	}

	public override componentDidMount() {
		this.removeOnSwitch = SideMenuStatus.getInstance().onSwitch((status) =>
			this.setState({
				status,
			}),
		);
		this.removeUserOnChange = UserStore.getInstance().onChange((user) => {
			this.setState({
				userAddress: user?.userAddress,
			});
		});

		this._rootRef.current?.addEventListener("scroll", this.onScroll);

		this.intervalToScroll = setInterval(() => {
			if (this.state.isScrolling) this.setState({ isScrolling: false });
		}, 1000);
	}

	public override componentWillUnmount() {
		this.removeOnSwitch();
		this.removeUserOnChange();
		this._rootRef.current?.removeEventListener("scroll", this.onScroll);
		if (this.intervalToScroll) clearInterval(this.intervalToScroll);
	}

	private onScroll() {
		this.setState({
			isScrolling: true,
		});
	}

	private closeSideMenu() {
		SideMenuStatus.getInstance().close();
	}

	private openBecomeCreatorModal() {
		this.setState({
			isBecomeCreatorModalOpen: true,
		});
	}

	private showModalAboutUsPopup() {
		AboutUsPopupStore.getInstance().open();
	}
}

export default function SideMenu(props: IProps) {
	const navigate = useNavigate();
	return <SideMenuOpenedClass navigate={navigate} {...props} />;
}
