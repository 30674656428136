import I18n from "Components/Materials/I18n";
import Module from "Components/Materials/Module";
import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	type?: "new" | "hot";
	icon?: JSX.Element;
	onClick?: () => void;
};

type IState = {};

export default class AboutUs extends React.Component<IProps, IState> {
	public override render(): JSX.Element {
		const aboutUs = Module.config.AboutUs;
		return (
			<Module from={aboutUs}>
				<div className={classes["website-links"]}>
					<Module from={aboutUs.props.Discord}>
						<a className={classes["link"]} href={aboutUs.props.Discord.props.url} target="_blank" rel="noreferrer">
							<p>{I18n.translate("popups.about_us.links.discord")}</p>
						</a>
					</Module>
					<Module from={aboutUs.props.AboutUsPopup}>
						<div className={classes["link"]} onClick={this.props.onClick}>
							<p>{I18n.translate("popups.about_us.title")}</p>
						</div>
					</Module>
				</div>
			</Module>
		);
	}
}
