import React from "react";

import classes from "./classes.module.scss";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy.svg";

type IProps = {
	text: string;
	copyIcon?: boolean;
	className?: string;
};
type IState = {};

export default class AddressShort extends React.Component<IProps, IState> {
	public override render(): JSX.Element {
		let classToAdd = classes["root"];
		if (this.props.className) {
			classToAdd = this.props.className;
		}

		return (
			<span title={this.props.text} className={classToAdd}>
				{this.formatAddress(this.props.text)}
				{this.props.copyIcon && <CopyIcon className={classes["copy-icon"]} />}
			</span>
		);
	}

	private formatAddress(address: String) {
		let length = address.length;
		if (length <= 15) return address;
		return address.substring(0, 5) + "..." + address.substring(length - 4, length);
	}
}
