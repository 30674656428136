import React from "react";
import classNames from "classnames";
import classes from "./classes.module.scss";

import I18n from "Components/Materials/I18n";
import WindowStore from "Stores/WindowStore";

type IProps = {
	text?: string | null;
	maxHeight: number;
	className?: string;
};

type IState = {
	deployedDescription: boolean;
	windowWidth: number;
	textBoxHeight: number;
};

export default class ViewMore extends React.Component<IProps, IState> {
	private removeOnresize = () => {};
	private contentRef = React.createRef<HTMLDivElement>();

	constructor(props: IProps) {
		super(props);
		this.state = {
			deployedDescription: false,
			windowWidth: window.innerWidth,
			textBoxHeight: 0,
		};

		this.toggleDescription = this.toggleDescription.bind(this);
		this.onResize = this.onResize.bind(this);
	}

	public override render(): JSX.Element | null {
		const { text } = this.props;

		if (!text) return null;

		return (
			<div className={classNames(classes["root"], this.props.className)}>
				<div
					className={classes["content"]}
					data-deployed={this.state.deployedDescription}
					style={{ maxHeight: this.state.deployedDescription ? "max-content" : this.props.maxHeight }}>
					<p ref={this.contentRef}>{text} </p>
				</div>
				{this.state.textBoxHeight > this.props.maxHeight && (
					<strong onClick={this.toggleDescription}>
						{this.state.deployedDescription ? <I18n map="general_text.less" /> : <I18n map="general_text.more" />}
					</strong>
				)}
			</div>
		);

		// return (
		// 	<div className={classes["root"]}>
		// 		{this.state.deployedDescription ? (
		// 			<p>
		// 				{text && text}{" "}
		// 				<strong onClick={this.toggleDescription}>
		// 					<I18n map="general_text.less" />
		// 				</strong>
		// 			</p>
		// 		) : (
		// 			<p>
		// 				{text && text.slice(0, maxLength)}
		// 				{dots}&nbsp;
		// 				<strong onClick={this.toggleDescription}>
		// 					<I18n map="general_text.more" />
		// 				</strong>
		// 			</p>
		// 		)}
		// 	</div>
		// );
	}

	public override async componentDidMount() {
		this.removeOnresize = WindowStore.getInstance().onResize(this.onResize);
		this.setState({ textBoxHeight: this.contentRef.current?.scrollHeight ?? 0 });
	}

	public override componentWillUnmount() {
		this.removeOnresize();
	}

	private onResize(window: Window) {
		const textBoxHeight = this.contentRef.current?.scrollHeight ?? 0;
		this.setState({ windowWidth: window.innerWidth, textBoxHeight });
		if (this.state.deployedDescription && textBoxHeight <= this.props.maxHeight) this.setState({ deployedDescription: false });
	}

	private toggleDescription() {
		this.setState((prevState) => ({ deployedDescription: !prevState.deployedDescription }));
	}
}
