import IWalletInterface from "Services/Wallet/IWalletInterface";
import WalletFactory from "Services/Wallet/WalletFactory";
import Config from "Configs/Config";
import BeaconWallet from "Services/Wallet/Tezos/BeaconWallet";
import Web3auth from "Services/Wallet/Tezos/Web3auth";

export enum EWalletType {
	BEACON = "beacon",
	WEB3AUTH = "web3auth",
}

export default class Wallet {
	private static ctx: Wallet;
	private walletFactory: IWalletInterface;
	private walletType: EWalletType;
	private wallets = new Map<EWalletType, { new (): IWalletInterface }>();

	private constructor(walletType: EWalletType) {
		this.wallets.set(EWalletType.BEACON, BeaconWallet);
		this.wallets.set(EWalletType.WEB3AUTH, Web3auth);
		this.walletFactory = WalletFactory.create(this.wallets.get(walletType)!);
		this.walletFactory.autoConnect();
		this.walletType = walletType;
		Wallet.ctx = this;
	}

	public static getInstance(walletType?: EWalletType) {
		if (walletType && this.ctx && this.ctx.walletType !== walletType) new this(walletType);
		if (!walletType && !this.ctx) new this(Config.getInstance().get().wallet.type as EWalletType);
		return Wallet.ctx.walletFactory;
	}
}
