import React from "react";
import FullscreenStore, { FullScreenObj } from "Stores/FullscreenStore";
import classes from "./classes.module.scss";
import { ReactComponent as CrossIcon } from "assets/images/icons/cross.svg";
type IProps = {};
type IState = {
	fullScreen: FullScreenObj;
};

export default class Fullscreen extends React.Component<IProps, IState> {
	private removeOnChange = () => {};

	constructor(props: IProps) {
		super(props);

		this.state = {
			fullScreen: FullscreenStore.getInstance().fullScreen,
		};

		this.handleKeyDown = this.handleKeyDown.bind(this);
	}
	public override render() {
		if (!this.state.fullScreen.showing) return null;
		return (
			<div className={classes["root"]}>
				<div className={classes["cross-container"]}>
					<CrossIcon onClick={this.close} />
				</div>
				<div className={classes["element-container"]}>{this.state.fullScreen.element}</div>
			</div>
		);
	}

	public override componentDidMount() {
		this.removeOnChange = FullscreenStore.getInstance().onChange((fullScreen) => {
			if (fullScreen.showing) {
				document.body.style.overflow = "hidden";
			} else {
				document.body.style.overflow = "auto";
			}
			this.setState({
				fullScreen,
			});
		});
	}

	public override componentWillUnmount() {
		this.removeOnChange();
	}

	public override componentDidUpdate() {
		if (this.state.fullScreen.showing) {
			document.addEventListener("keydown", this.handleKeyDown);
		} else {
			document.removeEventListener("keydown", this.handleKeyDown);
		}
	}

	private handleKeyDown(e: KeyboardEvent) {
		const closableKeys = ["Escape", "Backspace"];
		if (closableKeys.includes(e.key)) {
			this.close();
		}
	}

	private close() {
		FullscreenStore.getInstance().hide();
	}
}
