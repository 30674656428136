import { CollectionOriginPath } from "Components/MainRoutes";
import CollectionBox from "Components/Materials/CollectionBox";
import { IUser } from "Entities/AuthFactory/user";
import { ICollection } from "Entities/Collection/collection";
import { CollectionStatus } from "Entities/Collection/enums/collectionStatus";
import React from "react";
import UserStore from "Stores/UserStore";

type IProps = {
	collections: ICollection[];
	editable: boolean;
	onlyOnchainCollections?: boolean;
	isClickable?: boolean;
	originPath: CollectionOriginPath;
};

type IState = {
	favoritesIds: string[];
	userConnected: IUser | null;
};

export default class CollectionList extends React.Component<IProps, IState> {
	private removeOnChange = () => {};
	static defaultProps = {
		editable: true,
		onlyOnchainCollections: false,
	};

	public constructor(props: IProps) {
		super(props);

		this.state = {
			favoritesIds: [],
			userConnected: UserStore.getInstance().user,
		};
	}

	public override render(): React.ReactNode {
		return (
			<div style={{ paddingBottom: this.props.originPath === CollectionOriginPath.MYCOLLECTIONS ? "100px" : 0 }}>
				{this.props.collections.map((collection) => {
					if (this.props.onlyOnchainCollections && !collection.contractAddress) return null;
					let clickable = true;
					if ((collection.incoming && collection.status !== CollectionStatus.ONCHAIN) && collection.owner?.userAddress !== this.state.userConnected?.userAddress) {
						clickable = false;
					}
					return (
						<CollectionBox
							key={collection.id}
							collection={collection}
							clickable={this.props.isClickable ?? clickable}
							editable={this.props.editable}
							originPath={this.props.originPath}
						/>
					);
				})}
			</div>
		);
	}

	public override componentDidMount() {
		this.removeOnChange = UserStore.getInstance().onChange((user) => {
			this.setState({
				userConnected: user,
			});
		});
	}

	public override componentWillUnmount() {
		this.removeOnChange();
	}
}
