import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import StoreWorkflow from "Services/StoreWorkflow";
import Version, { IConfig } from "Stores/Version";
import RouterLocation from "Stores/RouterLocation";

//	Pages
import CollectionsIncoming from "./Pages/Mint/CollectionsIncoming";
import CollectionsOnSale from "./Pages/Mint/CollectionsOnSale";

// Components
import I18n from "./Materials/I18n";
import Module from "./Materials/Module";
import Fullscreen from "./Elements/Fullscreen";
import Rules, { RulesMode } from "./Materials/Rules";
import { AppRuleActions, AppRuleNames } from "Entities/AuthFactory/rule";
import { NewRequests } from "./Pages/ManageCreator/NewRequests";
import { AllCreators } from "./Pages/ManageCreator/AllCreators";
import NotFound from "./Pages/NotFound";

export enum CollectionOriginPath {
	MINT,
	MYCOLLECTIONS,
}

type IProps = {};

type IState = {
	version: IConfig | null;
};

export default class MainRoutes extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.state = {
			version: Version.getInstance().version,
		};
	}

	public override render(): JSX.Element {
		return (
			<Router basename="/">
				<Suspense fallback={<div>Loading...</div>}>
					{this.loadComponent("ToastsHandler")}
					{this.loadComponent("PseudoPopup")}
					{this.loadComponent("AboutUsPopup")}
					<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.create }]}>
						{this.loadComponent("AddCreatorPopup")}
						{this.loadComponent("RejectCreatorPopup")}
						{this.loadComponent("ApproveCreatorPopup")}
						{this.loadComponent("EditCreatorPopup")}
						{this.loadComponent("DeleteCollectionPopup")}
					</Rules>
					<Fullscreen />
					<Routes>
						<Route element={<BindRouter version={this.state.version} />}>
							{this.routes()}
							<Route element={<Navigate to="/" replace />} path="*" />
						</Route>
					</Routes>
				</Suspense>
			</Router>
		);
	}

	private routes(): React.ReactElement | null {
		const pageConfig = Module.config.pages;
		return (
			<>
				<Route path="*" element={<Navigate to={Module.config.pages.NotFound.props.path} replace />} />
				<Route path={Module.config.pages.NotFound.props.path} element={<NotFound />} />
				<Route element={<Module from={pageConfig.Home}>{this.loadComponent("Home")}</Module>} path={pageConfig.Home.props.path} />
				<Route
					element={
						<Module from={pageConfig.Mint} isPage>
							{this.loadComponent("Mint")}
						</Module>
					}
					path={pageConfig.Mint.props.path.concat("/*")}>
					<Route path="collections-on-sale" element={<CollectionsOnSale />} />
					<Route path="collections-incoming" element={<CollectionsIncoming />} />
					<Route element={<Navigate to="collections-on-sale" replace />} path="*" />
				</Route>
				<Route
					path={pageConfig.Mint.pages.MintCollectionDetail.props.path}
					element={
						<Module from={pageConfig.Mint.pages.MintCollectionDetail} isPage>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.read }]} isPage>
								{this.loadComponent("MintCollectionDetail")}
							</Rules>
						</Module>
					}
				/>
				<Route
					element={
						<Module from={pageConfig.MyCollections} isPage>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]} isPage>
								{this.loadComponent("MyCollections")}
							</Rules>
						</Module>
					}
					path={pageConfig.MyCollections.props.path.concat("/*")}
				/>
				<Route
					path={pageConfig.MyCollectionDetail.props.path}
					element={
						<Module from={pageConfig.MyCollectionDetail} isPage>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]} isPage>
								{this.loadComponent("MyCollectionDetail")}
							</Rules>
						</Module>
					}
				/>
				{/* Edit profile basic */}
				<Route
					element={
						<Module from={pageConfig.EditProfile} isPage>
							<Rules
								mode={RulesMode.NECESSARY}
								rules={[{ name: AppRuleNames.users, action: AppRuleActions.updateUserInfoSelf }]}
								isPage>
								{this.loadComponent("EditProfile")}
							</Rules>
						</Module>
					}
					path={pageConfig.EditProfile.props.path}
				/>
				{/* Edit another user profile when admin */}
				<Route
					element={
						<Module from={pageConfig.EditProfile} isPage>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.update }]} isPage>
								{this.loadComponent("EditProfileAdmin")}
							</Rules>
						</Module>
					}
					path={pageConfig.EditProfile.props.path.concat("/:id")}
				/>
				{/* Create a profile when admin */}
				<Route
					element={
						<Module from={pageConfig.CreateProfile} isPage>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.create }]} isPage>
								{this.loadComponent("EditProfileAdmin")}
							</Rules>
						</Module>
					}
					path={pageConfig.CreateProfile.props.path}
				/>
				<Route
					element={
						<Module from={pageConfig.CreateCollection} isPage>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]} isPage>
								{this.loadComponent("CreateCollection")}
							</Rules>
						</Module>
					}
					path={pageConfig.CreateCollection.props.path}
				/>
				<Route
					element={
						<Module from={pageConfig.EditCollection} isPage>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]} isPage>
								{this.loadComponent("CreateCollectionEditionMode")}
							</Rules>
						</Module>
					}
					path={pageConfig.EditCollection.props.path}
				/>
				<Route
					element={
						<Module from={pageConfig.CreateAsset} isPage>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]} isPage>
								{this.loadComponent("CreateAsset")}
							</Rules>
						</Module>
					}
					path={pageConfig.CreateAsset.props.path}
				/>
				<Route
					element={
						<Module from={pageConfig.EditAsset} isPage>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]} isPage>
								{this.loadComponent("CreateAssetEditionMode")}
							</Rules>
						</Module>
					}
					path={pageConfig.EditAsset.props.path}
				/>
				<Route
					element={
						<Module from={pageConfig.AssetDetail} isPage>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.read }]} isPage>
								{this.loadComponent("AssetDetail")}
							</Rules>
						</Module>
					}
					path={pageConfig.AssetDetail.props.path}
				/>
				<Route
					element={
						<Module from={pageConfig.Profile} isPage>
							{/* <Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.read }]} isPage> */}
							{this.loadComponent("Profile")}
							{/* </Rules> */}
						</Module>
					}
					path={pageConfig.Profile.props.path}
				/>
				<Route
					element={
						<Module from={pageConfig.Whitelist}>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.update }]} isPage>
								{this.loadComponent("Whitelist")}
							</Rules>
						</Module>
					}
					path={pageConfig.Whitelist.props.path}
				/>
				<Route element={<Module from={pageConfig.SignIn}>{this.loadComponent("SignIn")}</Module>} path={pageConfig.SignIn.props.path} />
				<Route element={<Module from={pageConfig.Register}>{this.loadComponent("Register")}</Module>} path={pageConfig.Register.props.path} />
				<Route
					element={
						<Module from={pageConfig.BecomeCreator}>
							<Rules no mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]} isPage>
								{this.loadComponent("BecomeCreator")}
							</Rules>
						</Module>
					}
					path={pageConfig.BecomeCreator.props.path}
				/>
				{/* Manage creator when admin */}
				<Route
					element={
						<Module from={pageConfig.ManageCreators} isPage>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.create }]} isPage>
								{this.loadComponent("ManageCreators")}
							</Rules>
						</Module>
					}
					path={pageConfig.ManageCreators.props.path.concat("/*")}>
					<Route path="new-requests" element={<NewRequests />} />
					<Route path="all-creators" element={<AllCreators />} />
					<Route element={<Navigate to="new-requests" replace />} path="*" />
				</Route>
			</>
		);
	}

	private loadComponent(name: string): JSX.Element {
		switch (name) {
			case "Mint":
				const Mint = lazy(() => import("./Pages/Mint"));
				return <Mint />;
			case "Home":
				const Home = lazy(() => import("./Pages/Home"));
				return <Home />;
			case "MyCollections":
				const MyCollections = lazy(() => import("./Pages/MyCollections"));
				return <MyCollections />;
			case "CreateCollectionEditionMode":
				const CreateCollectionEditionMode = lazy(() => import("./Pages/CreateCollection"));
				return <CreateCollectionEditionMode isEditionMode />;
			case "CreateCollection":
				const CreateCollection = lazy(() => import("./Pages/CreateCollection"));
				return <CreateCollection />;
			case "CreateAsset":
				const CreateAsset = lazy(() => import("./Pages/CreateAsset"));
				return <CreateAsset />;
			case "CreateAssetEditionMode":
				const EditAsset = lazy(() => import("./Pages/EditAsset"));
				return <EditAsset />;
			case "Profile":
				const Profile = lazy(() => import("./Pages/Profile"));
				return <Profile />;
			case "EditProfile":
				const EditProfile = lazy(() => import("./Pages/EditProfile"));
				return <EditProfile isAdmin={false} />;
			case "EditProfileAdmin":
				const EditProfileAdmin = lazy(() => import("./Pages/EditProfile"));
				return <EditProfileAdmin isAdmin={true} />;
			case "Whitelist":
				const Whitelist = lazy(() => import("./Pages/Whitelist"));
				return <Whitelist />;
			case "BecomeCreator":
				const BecomeCreator = lazy(() => import("./Pages/BecomeCreator"));
				return <BecomeCreator />;
			case "SignIn":
				const SignIn = lazy(() => import("./Pages/SignIn"));
				return <SignIn />;
			case "Register":
				const Register = lazy(() => import("./Pages/Register"));
				return <Register />;
			case "ManageCreators":
				const ManageCreators = lazy(() => import("./Pages/ManageCreator"));
				return <ManageCreators />;
			case "MintCollectionDetail":
				const MintCollectionDetail = lazy(() => import("./Pages/CollectionDetail"));
				return <MintCollectionDetail originPath={CollectionOriginPath.MINT} />;
			case "MyCollectionDetail":
				const MyCollectionDetail = lazy(() => import("./Pages/CollectionDetail"));
				return <MyCollectionDetail originPath={CollectionOriginPath.MYCOLLECTIONS} />;
			case "AssetDetail":
				const AssetDetail = lazy(() => import("./Pages/AssetDetail"));
				return <AssetDetail />;
			case "PseudoPopup":
				const PseudoPopup = lazy(() => import("./Materials/PseudoPopup"));
				return <PseudoPopup />;
			case "ToastsHandler":
				const ToastsHandler = lazy(() => import("./Materials/ToastsHandler"));
				return <ToastsHandler />;
			case "AddCreatorPopup":
				const AddCreatorPopup = lazy(() => import("./Pages/ManageCreator/Elements/AddCreatorPopup"));
				return <AddCreatorPopup />;
			case "RejectCreatorPopup":
				const RejectCreatorPopup = lazy(() => import("./Pages/ManageCreator/Elements/RejectCreatorPopup"));
				return <RejectCreatorPopup />;
			case "ApproveCreatorPopup":
				const ApproveCreatorPopup = lazy(() => import("./Pages/ManageCreator/Elements/ApproveCreatorPopup"));
				return <ApproveCreatorPopup />;
			case "AboutUsPopup":
				const AboutUsPopup = lazy(() => import("./Elements/AboutUsPopup"));
				return <AboutUsPopup />;
			case "EditCreatorPopup":
				const EditCreatorPopup = lazy(() => import("./Pages/ManageCreator/Elements/EditCreatorPopup"));
				return <EditCreatorPopup />;
			default:
				return <></>;
		}
	}
}

function BindRouter({ version }: { version: IConfig | null }) {
	const ComingSoon = lazy(() => import("./Pages/ComingSoon"));
	const location = useLocation();

	RouterLocation.getInstance().preSet(location);
	useEffect(() => {
		if (RouterLocation.getInstance().pushEnabled) {
			RouterLocation.getInstance().push(location);
		} else {
			RouterLocation.getInstance().change(location);
		}
		window.scrollTo(0, 0);
		StoreWorkflow.getInstance().closeOnTopLayouts();
		document.body.setAttribute("route", location.pathname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	if (version && version.pagesComingSoon) {
		const pageComingSoon = version?.pagesComingSoon[location.pathname];
		if (pageComingSoon?.enabled) {
			return (
				<I18n
					map={[pageComingSoon.titleLocalizationKey, pageComingSoon.textLocalizationKey]}
					content={([title, translatedText]) => <ComingSoon title={title!} text={translatedText!} />}
				/>
			);
		}
	}
	return <Outlet />;
}
