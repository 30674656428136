import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { JwtPair } from "Entities/AuthFactory/jwtPair";

export default class RefreshToken extends BaseApiService {
	//#region singleton logic
	static instance: RefreshToken;
	protected static readonly baseUrl = Config.getInstance()
		.get()
		.api.authFactory.concat("/app-auth/")
		.concat(Config.getInstance().get().app)
		.concat("/refreshToken");

	static getInstance() {
		if (!RefreshToken.instance) {
			RefreshToken.instance = new RefreshToken();
		}

		return RefreshToken.instance;
	}
	//#endregion singleton logic

	public async post(jwtPair: JwtPair): Promise<JwtPair> {
		const url = new URL(RefreshToken.baseUrl);
		try {
			return this.postRequest(url, jwtPair);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
