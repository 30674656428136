import Account from "Api/Factory/AppUser/Account";
import TezosDomains from "Api/TezosDomains/TezosDomains";
import { IUser } from "Entities/AuthFactory/user";
import EventService from "Services/EventEmitter";

export default class UserStore {
	private static ctx: UserStore;
	private readonly event = new EventService();

	private _user: IUser | null = null;

	private constructor() {
		UserStore.ctx = this;
	}

	public static getInstance() {
		if (!UserStore.ctx) new this();
		return UserStore.ctx;
	}

	public get user() {
		return this._user;
	}

	public onChange(callback: (user: IUser | null) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public setUser(user: IUser | null) {
		this._user = user;
		this.event.emit("change", user);
	}

	public async loadUser(): Promise<IUser | null> {
		try {
			let user = await Account.getInstance().get();

			const domain = await TezosDomains.getInstance().getDomainName({
				address: user.userAddress!,
			});

			if (domain && domain.domain && domain.domain.name) {
				user.userAddressDomain = domain.domain.name;
			}

			this.setUser(user);
			return user;
		} catch (err) {
			console.error(err);
			return null;
		}
	}

	public disconnect() {
		this.setUser(null);
	}
}
