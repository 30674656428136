//	Components
import BasePage from "Components/Pages/Base";
import { IPendingScroll } from "Components/Elements/InfinitScroll";
import User from "Api/Factory/AppUser/User";
import { IUser } from "Entities/AuthFactory/user";
import CreatorsComponent from "./CreatorsComponent";

type IProps = {};

type IState = {
	creators: IUser[];
	hasMoreToLoad: boolean;
};

export class AllCreators extends BasePage<IProps, IState> {
	private static PAGE_SIZE = 10;

	constructor(props: IProps) {
		super(props);
		this.state = {
			creators: [],
			hasMoreToLoad: true,
		};

		this.fetchCreatorApplications = this.fetchCreatorApplications.bind(this);
	}

	public override render(): JSX.Element {
		return <CreatorsComponent onNextInfiniteScroll={this.fetchCreatorApplications} creators={this.state.creators} />;
	}

	private async fetchCreatorApplications(pendingScroll?: IPendingScroll): Promise<void> {
		if (!this.state.hasMoreToLoad) {
			pendingScroll?.reject();
			return;
		}
		const creators = await User.getInstance().getAll(
			{ app_roles: { name: { $eq: "creator" } } },
			{
				pageSize: AllCreators.PAGE_SIZE,
				page: Math.ceil(this.state.creators.length / AllCreators.PAGE_SIZE) + 1,
			},
			{ becomeCreatorAt: "desc" },
		);
		this.setState(
			{
				creators: [...this.state.creators, ...creators.data],
				hasMoreToLoad: !(creators.data.length < AllCreators.PAGE_SIZE),
			},
			() => {
				pendingScroll?.resolve();
			},
		);
	}
}
