//	Components
import BasePage from "Components/Pages/Base";
import { IPendingScroll } from "Components/Elements/InfinitScroll";
import CreatorForm from "Api/Factory/AppCreatorForm/CreatorForm";
import { IAppCreatorForm } from "Entities/CreatorForm/creatorForm";
import NewRequestElement from "./NewRequestElement";
import RejectCreatorPopupStore from "Stores/RejectCreatorPopupStore";
import ApproveCreatorPopupStore from "Stores/ApproveCreatorPopupStore";
import { ECreatorFormStatus } from "Entities/CreatorForm/enums/CreatorFormStatus";

type IProps = {};

type IState = {
	creatorForms: IAppCreatorForm[];
	hasMoreToLoad: boolean;
};

export class NewRequests extends BasePage<IProps, IState> {
	private static PAGE_SIZE = 10;
	private removeOnChangeRejectCreatorForm = () => {};
	private removeOnChangeApproveCreatorForm = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			creatorForms: [],
			hasMoreToLoad: true,
		};
		this.onReject = this.onReject.bind(this);
		this.onApprove = this.onApprove.bind(this);
		this.fetchCreatorApplications = this.fetchCreatorApplications.bind(this);
	}

	public override render(): JSX.Element {
		return <NewRequestElement onNextInfiniteScroll={this.fetchCreatorApplications} creatorForms={this.state.creatorForms} />;
	}

	public override async componentDidMount() {
		this.removeOnChangeRejectCreatorForm = RejectCreatorPopupStore.getInstance().onCreatorformChange(this.onReject);
		this.removeOnChangeApproveCreatorForm = ApproveCreatorPopupStore.getInstance().onCreatorformChange(this.onApprove);
		await this.fetchCreatorApplications();
	}

	public override componentWillUnmount() {
		this.removeOnChangeRejectCreatorForm();
		this.removeOnChangeApproveCreatorForm();
	}

	private async onReject(creatorForm: IAppCreatorForm) {
		if (creatorForm.status !== ECreatorFormStatus.REJECTED) return;
		this.setState({ hasMoreToLoad: true, creatorForms: [] }, async () => {
			await this.fetchCreatorApplications();
		});
	}

	private async onApprove(creatorForm: IAppCreatorForm) {
		if (creatorForm.status !== ECreatorFormStatus.ACCEPTED) return;
		this.setState({ hasMoreToLoad: true, creatorForms: [] }, async () => {
			await this.fetchCreatorApplications();
		});
	}

	private async fetchCreatorApplications(pendingScroll?: IPendingScroll): Promise<void> {
		if (!this.state.hasMoreToLoad) {
			pendingScroll?.reject();
			return;
		}
		const creators = await CreatorForm.getInstance().getAll(
			{ status: ECreatorFormStatus.PENDING },
			{
				pageSize: NewRequests.PAGE_SIZE,
				page: Math.ceil(this.state.creatorForms.length / NewRequests.PAGE_SIZE) + 1,
			},
		);
		this.setState(
			{
				creatorForms: [...this.state.creatorForms, ...creators.data],
				hasMoreToLoad: !(creators.data.length < NewRequests.PAGE_SIZE),
			},
			() => {
				pendingScroll?.resolve();
			},
		);
	}
}
