import React from "react";
import classes from "./classes.module.scss";
import I18n from "Components/Materials/I18n";
import Module from "Components/Materials/Module";
import SideMenu from "Components/Materials/SideMenu";
import TopMenu from "Components/Materials/TopMenu";
import LetsCreatePopup from "Components/Materials/LetsCreatePopup";
import TopMenuStatus, { EOpeningState as EOpeningStateTop } from "Stores/TopMenuStatus";
import classNames from "classnames";
import SideMenuStatus, { EOpeningState } from "Stores/SideMenuStatus";
import HelmetMaterial from "Components/Materials/HelmetMaterial";

type IProps = {
	title?: string;
	children?: React.ReactChild | (React.ReactChild | null)[] | null;
	className?: string;
	padding?: boolean;
};
type IState = {
	isMenuDisabled: boolean;
	status: EOpeningState;
};

export const MenuInactivPath = {
	REGISTER: Module.config.pages.Register.props.path,
};

export default class DefaultTemplate extends React.Component<IProps, IState> {
	private readonly _contentDivId = "content";
	private removeOnSideMenuStateChange = () => {};
	private removeOnMenuStateChange = () => {};
	static defaultProps: IProps = {
		title: "",
		padding: true,
	};

	constructor(props: IProps) {
		super(props);
		this.state = {
			isMenuDisabled: Object.values(MenuInactivPath).includes(window.location.pathname),
			status: SideMenuStatus.getInstance().status,
		};
		this.toggleScroll = this.toggleScroll.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<I18n>
				<HelmetMaterial />
				<LetsCreatePopup />
				<Module from={Module.config.SideMenu}>
					<SideMenu isMenuDisabled={this.state.isMenuDisabled} />
					<TopMenu isMenuDisabled={this.state.isMenuDisabled} />
				</Module>
				<div id={this._contentDivId} className={classNames(classes["root"], classes[SideMenuStatus.getInstance().status])}>
					<h1 className={classes["for-seo"]}>Minteed App | Create, Explore & Collect NFT artworks</h1>
					<h2 className={classes["for-seo"]}>Minteed is a Web3 ArtTech platform to create and collect NFT artworks.</h2>
					<div className={classNames(classes["content"], this.props.className)} data-padding={this.props.padding}>
						{this.props.children}
					</div>
				</div>
			</I18n>
		);
	}

	public override componentDidMount() {
		this.removeOnSideMenuStateChange = SideMenuStatus.getInstance().onSwitch((status) =>
			this.setState({
				status,
			}),
		);
		this.props.title && this.updateWindowTitle();
		this.removeOnMenuStateChange = TopMenuStatus.getInstance().onSwitch(this.toggleScroll);
	}

	public override componentWillUnmount() {
		this.removeOnSideMenuStateChange();
		this.removeOnMenuStateChange();
	}

	public override componentDidUpdate() {
		this.props.title && this.updateWindowTitle();
	}

	private toggleScroll(status: EOpeningStateTop) {
		if (status === EOpeningStateTop.OPENED) {
			window.document.body.style.overflow = "hidden";
		} else {
			window.document.body.style.overflow = "auto";
		}
	}

	private updateWindowTitle() {
		if (this.props.title) window.document.title = this.props.title;
	}
}
