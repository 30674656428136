import RefreshToken from "Api/Factory/AppAuth/RefreshToken";
import Message from "Api/Factory/AppAuth/Message";
import Presign from "Api/Factory/AppAuth/Presign";
import Signin from "Api/Factory/AppAuth/Signin";
import EventService from "Services/EventEmitter";
import Wallet from "./Wallet/Wallet";
import jwt from "jsonwebtoken";
import { IAppRole } from "Entities/AuthFactory/role";
import { IAppRule } from "Entities/AuthFactory/rule";
import { JwtPair } from "Entities/AuthFactory/jwtPair";

const JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY = "AUTH_TOKEN";
const JWT_REFRESH_TOKEN_LOCALSTORAGE_KEY = "REFRESH_TOKEN";

export interface IJwtToken {
	email: string;
	userAddress: string;
	app_roles: IAppRole[];
}

export default class JwtStore {
	private static ctx: JwtStore;

	private _accessToken: string | null = localStorage.getItem(JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY);
	private _refreshToken: string | null = localStorage.getItem(JWT_REFRESH_TOKEN_LOCALSTORAGE_KEY);
	private readonly event = new EventService();

	private constructor() {
		JwtStore.ctx = this;
		if (!this.accessToken) {
			this.presign();
		}
	}

	public static getInstance() {
		if (!JwtStore.ctx) new this();
		return JwtStore.ctx;
	}

	public get accessToken() {
		return this._accessToken;
	}

	public get refreshToken() {
		return this._refreshToken;
	}

	public set accessToken(token: string | null) {
		this._accessToken = token;
	}

	public set refreshToken(token: string | null) {
		this._refreshToken = token;
	}

	public setJwtPair(jwtPair: JwtPair) {
		this.accessToken = jwtPair.accessToken;
		localStorage.setItem(JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY, jwtPair.accessToken);

		this.refreshToken = jwtPair.refreshToken;
		localStorage.setItem(JWT_REFRESH_TOKEN_LOCALSTORAGE_KEY, jwtPair.refreshToken);

		this.event.emit("change", jwtPair);
	}

	/**
	 * @returns removelistener callback
	 */
	public onChange(callback: (jwtPair: JwtPair) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public async signin(): Promise<JwtPair | null> {
		try {
			const message = await Message.getInstance().post();

			const signedMessage = await Wallet.getInstance().signMessage(message);
			const publicKey = (await Wallet.getInstance().getWalletData().provider.wallet.walletProvider.client.getActiveAccount()).publicKey;
			const { jwtPair } = await Signin.getInstance().post({
				message,
				signedMessage,
				publicKey,
			});
			this.setJwtPair(jwtPair);
			return jwtPair;
		} catch (err: any) {
			console.error(err);
			return null;
		}
	}

	public async presign() {
		try {
			const jwtPair = await Presign.getInstance().post();
			this.setJwtPair(jwtPair);
		} catch (err) {
			console.error(err);
		}
	}

	public async refreshAccessToken() {
		try {
			const jwtPair: JwtPair = {
				accessToken: this.accessToken as string,
				refreshToken: this.refreshToken as string,
			};
			const newJwtPair = await RefreshToken.getInstance().post(jwtPair);
			this.setJwtPair(newJwtPair);
		} catch (err) {
			console.error(err);
		}
	}

	public hasRule(name: string, action: string) {
		if (!this._accessToken) return false;
		const decodedToken = jwt.decode(this._accessToken) as IJwtToken;
		return decodedToken?.["app_roles"]?.some((role: IAppRole) =>
			role.app_rules.some((rule: IAppRule) => rule.action === action && rule.name === name),
		);
	}
}
