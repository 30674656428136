import Config from "Configs/Config";
import { IUser } from "Entities/AuthFactory/user";
import ALike from "Stores/UserLike/ALike";
import UsersLikeStore from "Stores/UserLike/UsersLike";
import UserLike, { LikeableObject } from "./AUserLike";

export default class UserLikeUsers extends UserLike<IUser> {
	static instance: UserLikeUsers;

	static getInstance() {
		if (!UserLikeUsers.instance) {
			UserLikeUsers.instance = new UserLikeUsers();
		}

		return UserLikeUsers.instance;
	}

	protected getBaseUrl(): string {
		return `${Config.getInstance().get().api.authFactory}/${Config.getInstance().get().app}/app-user-like-users`;
	}

	protected getStoreInstance(): ALike<LikeableObject> {
		return UsersLikeStore.getInstance();
	}
}

