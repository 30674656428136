import EventService from "Services/EventEmitter";

export type HelmetContent = {
	title: string;
	description: string;
	image: string;
	url: string;
};

export default class HelmetStore {
	private static ctx: HelmetStore;

	private readonly event = new EventService();
	private _content: HelmetContent = {
		title: "Minteed App | Create, Explore & Collect NFT artworks",
		description: "Minteed is a Web3 ArtTech platform to create and collect NFT artworks.",
		image: "https://minteed.app/shared_image.png",
		url: "https://minteed.app/",
	};

	private constructor() {
		HelmetStore.ctx = this;
		this.setContent(this._content);
	}

	public static getInstance() {
		if (!HelmetStore.ctx) new this();
		return HelmetStore.ctx;
	}

	public get content() {
		return this._content;
	}

	/**
	 * @returns removelistener callback
	 */
	public onChange(callback: (content: HelmetContent) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public setContent(content: HelmetContent) {
		this._content = content;
		this.event.emit("change", content);
	}
}
