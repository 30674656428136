import BaseApiService from "Api/BaseApiService";
import { IUser } from "Entities/AuthFactory/user";
import { ICollection } from "Entities/Collection/collection";
import { IAsset } from "Entities/CollectionAsset/collectionAsset";
import { ILiked } from "Entities/Like";
import Toasts from "Stores/Toasts";
import ALikeStore from "Stores/UserLike/ALike";
import UserStore from "Stores/UserStore";
import I18n from "../../../Components/Materials/I18n";

interface IGetAllParams {
	user?: number;
}

interface ICreateParams {
	id: number;
}

export type LikeableObject = ICollection | IAsset | IUser;

export default abstract class UserLike<T extends LikeableObject> extends BaseApiService {
	protected abstract getBaseUrl(): string;
	protected abstract getStoreInstance(): ALikeStore<LikeableObject>;

	public async getAll(params: IGetAllParams): Promise<ILiked<T>[]> {
		const url = new URL(this.getBaseUrl());

		if (params) {
			url.searchParams.set("q", JSON.stringify(params));
		}

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async toggle(body: ICreateParams): Promise<ILiked<T>> {
		const url = new URL(this.getBaseUrl());

		try {
			const res: ILiked<T> = await this.postRequest(url, body);
			this.getStoreInstance().loadLikes(+(UserStore.getInstance().user?.id ?? 0));
			return res;
		} catch (err) {
			Toasts.getInstance().open({
				text: "",
				title: `${I18n.translate("general_text.connection_toaster_error")}`,
				time: 2250,
				closable: true,
			});
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
