import { IAppCreatorForm } from "Entities/CreatorForm/creatorForm";
import EventService from "Services/EventEmitter";

export default class ApproveCreatorPopupStore {
	private static ctx: ApproveCreatorPopupStore;

	private _opened: boolean = false;
	private _notShowButtons: boolean = false;
	private _creatorForm: IAppCreatorForm | null = null;
	private readonly event = new EventService();

	private constructor() {
		ApproveCreatorPopupStore.ctx = this;
	}

	public static getInstance() {
		if (!ApproveCreatorPopupStore.ctx) new this();
		return ApproveCreatorPopupStore.ctx;
	}

	public get opened() {
		return this._opened;
	}

	public get notShowButtons() {
		return this._notShowButtons;
	}

	public get creatorForm() {
		return this._creatorForm;
	}

	/**
	 * @returns removelistener callback
	 */
	public onChange(callback: (opened: boolean, creatorForm: IAppCreatorForm, notShowButtons: boolean) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public onCreatorformChange(callback: (creatorForm: IAppCreatorForm) => void) {
		this.event.on("change-creator-form", callback);
		return () => {
			this.event.off("change-creator-form", callback);
		};
	}

	public toggle() {
		this._opened = !this._opened;
		this.event.emit("change", this.opened, this.creatorForm, this.notShowButtons);
	}

	public open() {
		this._opened = true;
		this.event.emit("change", this.opened, this.creatorForm, this.notShowButtons);
	}

	public close() {
		this._opened = false;
		this.event.emit("change", this.opened, this.creatorForm, this.notShowButtons);
	}

	public setCreatorForm(creatorForm: IAppCreatorForm) {
		this._creatorForm = creatorForm;
		this.event.emit("change", this.opened, this.creatorForm, this.notShowButtons);
		this.event.emit("change-creator-form", this.creatorForm);
	}

	public setNotShowButtons(notShowButtons: boolean) {
		this._notShowButtons = notShowButtons;
		this.event.emit("change", this.opened, this.creatorForm, this.notShowButtons);
	}
}
