import { ReactComponent as Moon } from "assets/images/icons/moon.svg";
import { ReactComponent as DarkPrimary } from "assets/images/icons/dark-mode-icon-primary.svg";
// import { ReactComponent as LightSecondary } from "assets/images/icons/light-mode-icon-secondary.svg";
import { ReactComponent as DarkSecondary } from "assets/images/icons/dark-mode-icon-secondary.svg";
import React from "react";
import ThemeMode, { EThemeModeType } from "Stores/ThemeMode";
import classes from "./classes.module.scss";

type IProps = {
	onClick?: () => void;
	variant: "primary" | "secondary";
};

type IState = {
	theme: EThemeModeType;
};

export default class ThemeModeIcon extends React.Component<IProps, IState> {
	private removeOnSwitch = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			theme: ThemeMode.getInstance().type,
		};
	}

	public override render(): JSX.Element {
		return <div onClick={this.props.onClick} className={classes["root"]}>{this.getSrc()}</div>;
	}

	public override componentDidMount() {
		this.removeOnSwitch = ThemeMode.getInstance().onSwitch((theme) => this.setState({ theme }));
	}

	public override componentWillUnmount() {
		this.removeOnSwitch();
	}

	private getSrc() {
		if (this.props.variant === "primary") {
			if (this.state.theme === EThemeModeType.LIGHT) return <Moon/>;
			return <DarkPrimary />;
		}

		if (this.state.theme === EThemeModeType.LIGHT) return <Moon />;
		return <DarkSecondary />;
	}
}
