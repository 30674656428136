import BaseGraphQlService from "Api/BaseGraphQlService";
import Config from "Configs/Config";
import { gql } from "graphql-request";

interface IGetDomainParams {
	address: string;
}

interface IGetDomainsParams {
	addresses: string[];
}

interface IGetAddressParams {
	domain: string;
}

interface IGetAddressesParams {
	domains: string[];
}

export type ITezosDomain = {
	address: string;
	domain: {
		name: string;
	};
	owner: string;
};
export default class TezosDomains extends BaseGraphQlService {
	static instance: TezosDomains;
	protected static readonly baseUrl = Config.getInstance().get().api.graphQlTezosDomains;

	static getInstance() {
		if (!TezosDomains.instance) {
			TezosDomains.instance = new TezosDomains();
		}

		return TezosDomains.instance;
	}

	public async getAddresses(params: IGetAddressesParams): Promise<string[]> {
		const url = new URL(TezosDomains.baseUrl);
		try {
			const query = gql`
				{
					reverseRecords(where: { address: { in: ["KT1Mqx5meQbhufngJnUAGEGpa4ZRxhPSiCgB", "KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS"] } }) {
						items {
							address
							owner
							domain {
								name
							}
						}
					}
				}
			`;
			return this.graphQlRequest(url, query);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getAddress(params: IGetAddressParams): Promise<string[]> {
		const url = new URL(TezosDomains.baseUrl);
		try {
			const query = gql`
				{
					reverseRecords(where: { address: { in: ["KT1Mqx5meQbhufngJnUAGEGpa4ZRxhPSiCgB", "KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS"] } }) {
						items {
							address
							owner
							domain {
								name
							}
						}
					}
				}
			`;
			return this.graphQlRequest(url, query);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getDomainName(params: IGetDomainParams): Promise<ITezosDomain> {
		const url = new URL(TezosDomains.baseUrl);
		try {
			const query = gql`
				{
					reverseRecords(where: { address: { in: ["${params.address}"] } }) {
						items {
							address
							owner
							domain {
								name
							}
						}
					}
				}
			`;
			const request = await this.graphQlRequest(url, query);
			return request.reverseRecords?.items[0] ?? null;
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getDomainsName(params: IGetDomainsParams): Promise<ITezosDomain[]> {
		const url = new URL(TezosDomains.baseUrl);
		const requestString = params.addresses.map((address) => `"${address}"`).join(",");
		try {
			const query = gql`
				{
					reverseRecords(where: { address: { in: [${requestString}] } }) {
						items {
							address
							owner
							domain {
								name
							}
						}
					}
				}
			`;
			const request = await this.graphQlRequest(url, query);
			if (request.reverseRecords.items) {
				return request.reverseRecords.items;
			}
			return [];
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
