import React from "react";
import classes from "./classes.module.scss";
import Row from "./Row";

type IProps = {
	content: { [key: string]: string | JSX.Element };
	footer?: JSX.Element;
};
type IState = {};

export default class CardBox extends React.Component<IProps, IState> {
	override render() {
		const data = this.props.content;
		return (
			<div className={classes["root"]}>
				{Object.keys(this.props.content).map((key) => (
					<Row label={key} value={data[key]!} />
				))}
				{this.props.footer}
			</div>
		);
	}
}
