import React from "react";
import classes from "./classes.module.scss";
import classNames from "classnames";
import { HashLink } from "react-router-hash-link";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { ReactComponent as ProfileIcon } from "assets/images/icons/profile.svg";
import { ReactComponent as MintIcon } from "assets/images/icons/mint.svg";
import { ReactComponent as CollectionIcon } from "assets/images/icons/collection.svg";
import { ReactComponent as ToolsIcon } from "assets/images/icons/tools.svg";
import { ReactComponent as MenuArrowLight } from "assets/images/icons/menu-arrow-light.svg";
import { ReactComponent as MenuArrowDark } from "assets/images/icons/menu-arrow-dark.svg";
import { ReactComponent as HomeIcon } from "assets/images/icons/home.svg";
import Logo from "../../Logo";

import { AppRuleActions, AppRuleNames } from "Entities/AuthFactory/rule";
import { UserRole } from "Entities/AuthFactory/userInfos";

import UserStore from "Stores/UserStore";
import SideMenuStatus, { EOpeningState } from "Stores/SideMenuStatus";
import ThemeMode from "Stores/ThemeMode";
import AboutUsPopupStore from "Stores/AboutUsPopupStore";

import Module from "Components/Materials/Module";
import IsConnected from "Components/Materials/IsConnected";
import ProfileMenu from "../ProfileMenu";
import SignInButton from "Components/Materials/SignInButton";
import NavLinkElement from "Components/Elements/NavLinkElement";
import Rules, { RulesMode } from "Components/Materials/Rules";
import ThemeModeSwitcher from "Components/Materials/ThemeModeSwitcher";
import LogoutButton from "Components/Materials/LogoutButton";

type IState = {
	status: EOpeningState;
	isBecomeCreatorModalOpen: boolean;
	userAddress?: string | null;
	isScrolling: boolean;
};
type IProps = {
	isMenuDisabled?: boolean;
};

type IPropsClass = IProps & { navigate: NavigateFunction };

class SideMenuClosedClass extends React.Component<IPropsClass, IState> {
	private readonly _rootRef: React.RefObject<HTMLDivElement> = React.createRef();
	private intervalToScroll: NodeJS.Timer | null = null;

	private removeOnSwitch = () => {};
	private removeUserOnChange = () => {};

	constructor(props: IPropsClass) {
		super(props);
		this.state = {
			status: SideMenuStatus.getInstance().status,
			isBecomeCreatorModalOpen: false,
			userAddress: UserStore.getInstance().user?.userAddress ?? "",
			isScrolling: false,
		};

		this.openBecomeCreatorModal = this.openBecomeCreatorModal.bind(this);
		this.showModalAboutUsPopup = this.showModalAboutUsPopup.bind(this);
		this.onScroll = this.onScroll.bind(this);
	}

	public override render(): JSX.Element {
		const pageConfig = Module.config.pages;
		return (
			<div
				className={classNames(classes["root"], {
					[classes["hide-scrollbar"]!]: !this.state.isScrolling,
				})}
				ref={this._rootRef}>
				<header>
					<Module from={Module.config.Logo}>
						<HashLink className={classes["logo"]} to={this.props.isMenuDisabled ? "" : Module.config.Logo.props.path}>
							<Logo variant="small" />
						</HashLink>
					</Module>
				</header>

				<IsConnected>
					<ProfileMenu isReduced={true} />
				</IsConnected>

				<IsConnected no>
					<SignInButton isReduced={true} />
				</IsConnected>

				<nav>
					<Module from={pageConfig.Home}>
						<NavLinkElement to={pageConfig.Home.props.path} icon={<HomeIcon />} reduced={true} isDisabled={this.props.isMenuDisabled} />
					</Module>

					<Module from={pageConfig.Mint}>
						<NavLinkElement to={pageConfig.Mint.props.path} icon={<MintIcon />} reduced={true} isDisabled={this.props.isMenuDisabled} />
					</Module>

					<IsConnected>
						<Module from={pageConfig.MyCollections}>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.collections, action: AppRuleActions.create }]}>
								<NavLinkElement
									to={pageConfig.MyCollections.props.path}
									icon={<CollectionIcon />}
									reduced={true}
									isDisabled={this.props.isMenuDisabled}
								/>
							</Rules>
						</Module>

						<Module from={pageConfig.Profile}>
							{UserStore.getInstance().user?.app_user_info?.role !== UserRole.ADMIN && (
								<NavLinkElement
									to={pageConfig.Profile.props.path.replace(":address", this.state.userAddress ?? "")}
									icon={<ProfileIcon />}
									comingSoon={!pageConfig.Profile.enabled}
									reduced={true}
									isDisabled={this.props.isMenuDisabled}
								/>
							)}
						</Module>

						<Module from={pageConfig.ManageCreators}>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.update }]}>
								<NavLinkElement
									to={pageConfig.ManageCreators.props.path}
									icon={<ToolsIcon />}
									comingSoon={!pageConfig.ManageCreators.enabled}
									reduced={true}
									isDisabled={this.props.isMenuDisabled}
								/>
							</Rules>
						</Module>

						<Module from={pageConfig.CreateProfile}>
							<Rules mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.update }]}>
								<NavLinkElement
									to={pageConfig.CreateProfile.props.path}
									icon={<ToolsIcon />}
									comingSoon={!pageConfig.CreateProfile.enabled}
									reduced={true}
									isDisabled={this.props.isMenuDisabled}
								/>
							</Rules>
						</Module>

						<Module from={pageConfig.Profile}>
							{UserStore.getInstance().user?.app_user_info?.role === UserRole.ADMIN && (
								<NavLinkElement
									to={pageConfig.Profile.props.path.replace(":address", this.state.userAddress ?? "")}
									icon={<ProfileIcon />}
									comingSoon={!pageConfig.Profile.enabled}
									reduced={true}
									isDisabled={this.props.isMenuDisabled}
								/>
							)}
						</Module>
					</IsConnected>

					<Module from={pageConfig.Tools}>
						<NavLinkElement
							to={pageConfig.Tools.props.path}
							icon={<ToolsIcon />}
							comingSoon={!pageConfig.Tools.enabled}
							reduced={true}
							isDisabled={this.props.isMenuDisabled}
						/>
					</Module>
				</nav>

				<footer className={classes["footer-icons-action"]}>
					<ThemeModeSwitcher variant="secondary" />
					<div className={classes["menu-arrow-container"]} data-shrink={this.state.status}>
						{ThemeMode.getInstance().type === "light" ? (
							<MenuArrowLight onClick={this.openSideMenu} />
						) : (
							<MenuArrowDark onClick={this.openSideMenu} />
						)}
					</div>
					<LogoutButton />
				</footer>
			</div>
		);
	}

	public override componentDidMount() {
		this.removeUserOnChange = UserStore.getInstance().onChange((user) => {
			this.setState({
				userAddress: user?.userAddress,
			});
		});

		this._rootRef.current?.addEventListener("scroll", this.onScroll);
		this.intervalToScroll = setInterval(() => {
			if (this.state.isScrolling) this.setState({ isScrolling: false });
		}, 1000);
	}

	public override componentWillUnmount() {
		this.removeOnSwitch();
		this.removeUserOnChange();
		this._rootRef.current?.removeEventListener("scroll", this.onScroll);
		if (this.intervalToScroll) clearInterval(this.intervalToScroll);
	}

	private openSideMenu() {
		SideMenuStatus.getInstance().open();
	}

	private onScroll() {
		this.setState({
			isScrolling: true,
		});
	}

	private openBecomeCreatorModal() {
		this.setState({
			isBecomeCreatorModalOpen: true,
		});
	}

	private showModalAboutUsPopup() {
		AboutUsPopupStore.getInstance().open();
	}
}

export default function SideMenu(props: IProps) {
	const navigate = useNavigate();
	return <SideMenuClosedClass navigate={navigate} {...props} />;
}
