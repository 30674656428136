import React from "react";
import classNames from "classnames";
import classes from "./classes.module.scss";
import Footer from "./Elements/Footer";
import Header from "./Elements/Header";
import { ReactComponent as Cross } from "assets/images/icons/cross.svg";
import Loader from "./Elements/Loader";

// Images
import verticalImage from "../../../assets/images/imagePopupVertical.png";
import horizontalImage from "../../../assets/images/imagePopupHorizontal.png";

export type IPopupProps = {
	closeBtn: boolean;
	header?: string | JSX.Element;
	footer?: JSX.Element;
	textLoader?: string | JSX.Element;
	isOpen: boolean;
	onClose?: () => void;
	hasTransparentBackground?: boolean;
	hasContainerClosable?: boolean;
	withSideBackground?: boolean;
	noMaxWidth?: boolean;
};

type IState = {
	isOpen: boolean;
};

export default class Popup<P extends IPopupProps, S extends IState = IState> extends React.Component<P, S> {
	constructor(props: P) {
		super(props);
		this.closePopup = this.closePopup.bind(this);
		this.closePopupOnBackgroundClick = this.closePopupOnBackgroundClick.bind(this);

		(this.state as any) = {
			isOpen: props.isOpen ?? true,
		};
	}

	public override render(): JSX.Element | null {
		if (!this.state.isOpen) return null;

		return (
			<div className={classes["root"]} data-side-background={this.props.withSideBackground}>
				<div className={classes["background"]} onClick={this.closePopupOnBackgroundClick} />
				<div
					className={classNames(classes["container"], {
						[classes["transparant-background"]!]: this.props.hasTransparentBackground,
					})}
					onClick={this.props.hasContainerClosable && this.closePopup}>
					{this.props.closeBtn && <Cross className={classes["cross"]} onClick={this.closePopup} />}
					<div className={classes["sub-container"]}>
						{this.props.withSideBackground && (
							<div className={classes["banner"]}>
								<img className={classes["side-image"]} src={verticalImage} alt="side-background" />
							</div>
						)}
						{this.props.withSideBackground && (
							<div className={classes["banner"]}>
								<img className={classes["top-image"]} src={horizontalImage} alt="top-background" />
							</div>
						)}
						{this.props.header && <Header content={this.props.header} />}
						<div
							className={classNames(classes["content"], {
								[classes["no-max-width"]!]: this.props.noMaxWidth,
							})}>
							{this.props.children ? this.props.children : <Loader text={this.props.textLoader} />}
						</div>
						{this.props.children && this.props.footer && <Footer content={this.props.footer} />}
					</div>
				</div>
			</div>
		);
	}

	public override componentDidUpdate(prevProps: Readonly<P>): void {
		if (prevProps.isOpen !== this.props.isOpen) {
			this.setState({
				isOpen: this.props.isOpen,
			});
		}
	}

	private closePopupOnBackgroundClick() {
		if (!this.props.hasContainerClosable) return;
		this.closePopup();
	}

	protected closePopup() {
		this.setState({
			isOpen: false,
		});
		this.props.onClose?.();
	}
}
