import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { JwtTokenTypes } from "Entities/AuthFactory/token";

type IPostMessage = JwtTokenTypes.Unidentified;

export default class Message extends BaseApiService {
	//#region singleton logic
	static instance: Message;
	protected static readonly baseUrl = Config.getInstance().get().api.authFactory.concat("/app-auth/").concat(Config.getInstance().get().app);

	static getInstance() {
		if (!Message.instance) {
			Message.instance = new Message();
		}

		return Message.instance;
	}
	//#endregion singleton logic

	public async post(): Promise<IPostMessage> {
		const url = new URL(Message.baseUrl.concat("/message"));
		try {
			const payload = await this.postRequest(url);
			return payload.message as string;
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
