import EventService from "Services/EventEmitter";
import { ISearchableData } from "./SearchResultsStatus";

export enum EOpeningState {
	CLOSED = "closed",
	OPENED = "opened",
}

export default class TopSearchResultsStatus {
	private static ctx: TopSearchResultsStatus;

	private _status: EOpeningState = EOpeningState.CLOSED;
	private _history: ISearchableData[] =
		((localStorage.getItem("top-search-bar-history")
			? JSON.parse(localStorage.getItem("top-search-bar-history") ?? "")
			: null) as ISearchableData[]) ?? [];
	private _selectedValue: ISearchableData | null = null;
	private readonly event = new EventService();

	private constructor() {
		TopSearchResultsStatus.ctx = this;
		this.switch(this.status);
	}

	public static getInstance() {
		if (!TopSearchResultsStatus.ctx) new this();
		return TopSearchResultsStatus.ctx;
	}

	public get status() {
		return this._status;
	}

	public get history() {
		return this._history;
	}

	public get selectedValue() {
		return this._selectedValue;
	}

	/**
	 * @returns removelistener callback
	 */
	public onSwitch(callback: (status: EOpeningState) => void) {
		this.event.on("switch", callback);
		return () => {
			this.event.off("switch", callback);
		};
	}

	public onSelect(callback: (history: ISearchableData[], selectedValue: ISearchableData) => void) {
		this.event.on("select", callback);
		return () => {
			this.event.off("select", callback);
		};
	}

	public toggle() {
		if (this.status === EOpeningState.CLOSED) {
			this.switch(EOpeningState.OPENED);
			return EOpeningState.OPENED;
		}
		this.switch(EOpeningState.CLOSED);
		return EOpeningState.CLOSED;
	}

	public close() {
		this.switch(EOpeningState.CLOSED);
	}

	public open() {
		this.switch(EOpeningState.OPENED);
	}

	public selectResult(selected: ISearchableData) {
		this.select(selected);
	}

	private canSwitch: boolean = true;

	public userCanSwitch(canSwitch: boolean): void {
		this.canSwitch = canSwitch;
	}

	private switch(type: EOpeningState) {
		if (this.canSwitch) {
			if (type === this.status) return;
			this._status = type;
			this.event.emit("switch", this._status);
		}
	}

	private select(selected: ISearchableData) {
		this._history = this._history.filter((element) => element !== selected);
		this._history.push(selected);
		this._selectedValue = selected;
		localStorage.setItem("top-search-bar-history", JSON.stringify(this._history));
		this.event.emit("select", this._history, this._selectedValue);
	}
}
