import React from "react";
import classes from "./classes.module.scss";
import Popup from "Components/Elements/Popups";
import Button from "Components/Elements/Button";
import I18n from "Components/Materials/I18n";
import { Link } from "react-router-dom";
import Module from "../Module";
import { IAppCreatorForm } from "Entities/CreatorForm/creatorForm";
import CreatorForm from "Api/Factory/AppCreatorForm/CreatorForm";
import UserStore from "Stores/UserStore";
import { ECreatorFormStatus } from "Entities/CreatorForm/enums/CreatorFormStatus";
import { IUser } from "Entities/AuthFactory/user";

type IProps = {};
type IState = {
	creatorForm: IAppCreatorForm | null;
	user: IUser | null;
	show: boolean;
};

export default class LetsCreatePopup extends React.Component<IProps, IState> {
	private removeOnUserChange = () => {};
	constructor(props: IProps) {
		super(props);
		this.state = { creatorForm: null, user: UserStore.getInstance().user, show: false };
		this.updateUser = this.updateUser.bind(this);
		this.onClosePopup = this.onClosePopup.bind(this);
	}
	override render() {
		const { creatorForm } = this.state;
		if (!creatorForm) return null;
		if (creatorForm.status !== ECreatorFormStatus.ACCEPTED) return null;
		return (
			<div className={classes["root"]}>
				<Popup closeBtn={true} isOpen={this.state.show} withSideBackground onClose={this.onClosePopup}>
					<section className={classes["popup-container"]}>
						<section className={classes["content"]}>
							<h1>{I18n.translate("popups.congratulations.title")}</h1>
							<p className={classes["text"]}>{I18n.translate("popups.congratulations.content")}</p>
							<Link to={Module.config.pages.CreateCollection.props.path}>
								<Button variant={"primary"} onClick={this.onClosePopup}>
									{I18n.translate("popups.congratulations.letsCreate")}
								</Button>
							</Link>
						</section>
					</section>
				</Popup>
			</div>
		);
	}

	public override async componentWillUnmount() {
		this.removeOnUserChange();
	}

	private updateUser(user: IUser | null) {
		this.setState({
			user,
		});

		this.handleShowPopup();
	}

	private onClosePopup() {
		if (!this.state.user) return;
		const userAddress: string = this.state.user.userAddress ?? "";
		const localStorageKey = "become-creator-popup-".concat(userAddress);
		localStorage.setItem(localStorageKey, JSON.stringify(false));
		this.setState({ show: false });
	}

	private async handleShowPopup() {
		if (!this.state.user) return;
		const email = this.state.user.app_user_info?.email;
		if (!email) return;
		const creatorForm = await CreatorForm.getInstance().getOneByEmail(email);
		if (!creatorForm) return;
		const userAddress: string = this.state.user.userAddress ?? "";
		const localStorageKey = "become-creator-popup-".concat(userAddress);
		const show: boolean = localStorage.getItem(localStorageKey) ? JSON.parse(localStorage.getItem(localStorageKey) ?? "true") : true;
		this.setState({ creatorForm, show });
	}
}
