import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { JwtPair } from "Entities/AuthFactory/jwtPair";

export default class Presign extends BaseApiService {
	static instance: Presign;
	protected static readonly baseUrl = Config.getInstance().get().api.authFactory.concat("/app-auth/").concat(Config.getInstance().get().app);

	static getInstance() {
		if (!Presign.instance) {
			Presign.instance = new Presign();
		}

		return Presign.instance;
	}

	public async post(): Promise<JwtPair> {
		const url = new URL(Presign.baseUrl.concat("/presign"));
		try {
			return this.postRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
