import * as api from "@tzkt/sdk-api";
import Config from "Configs/Config";
import LightCache from "Services/LightCache";
import BaseContract from "./BaseContract";


api.defaults.baseUrl = Config.getInstance().get().blockchain.tezos.tzktApi;

export default class ProxyContract extends BaseContract {
	protected static ctx: ProxyContract;
	protected readonly minteedContractCache: LightCache;
	protected static readonly QUERY_DELAY = 8;

	constructor() {
		super();
		this.minteedContractCache = LightCache.getNewNameSpace();
	}

	public async addToWhitelist(userAddresses: string[]) {
		return await this.contract.methodsObject.authorize(userAddresses).send();
	}

	public async removeFromWhitelist(userAddresses: string[]) {
		return await this.contract.methodsObject.unauthorize(userAddresses).send();
	}
}

