import React from "react";

import ThemeMode, { EThemeModeType } from "Stores/ThemeMode";

import classes from "./classes.module.scss";
import ButtonSwitch from "Components/Elements/ButtonSwitch";
import I18n from "../I18n";
import ThemeModeIcon from "Components/Elements/ThemeModeIcon";
import Module from "../Module";

type IState = {
	status: EThemeModeType;
};
type IProps = {
	variant: "primary" | "secondary";
};
export default class ThemeModeSwitcher extends React.Component<IProps, IState> {
	private removeOnSwitch = () => { };

	static defaultProps: IProps = {
		variant: "primary",
	};

	public constructor(props: IProps) {
		super(props);
		this.updateTheme(ThemeMode.getInstance().type);
		this.state = {
			status: ThemeMode.getInstance().type,
		};
		this.toggle = this.toggle.bind(this);
		this.updateTheme = this.updateTheme.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<Module from={Module.config.DarkMode}>
				{this.props.variant === "primary" && (
					<div {...this.props} className={classes["root"]}>
						<div className={classes["first-part"]}>
							<ThemeModeIcon variant={this.props.variant} />
							<I18n map="components.header_menu.darkmode" />
						</div>
						<div className={classes["second-part"]} onClick={this.toggle}>
							<ButtonSwitch activeDefault={ThemeMode.getInstance().type === EThemeModeType.LIGHT} />
						</div>
					</div>
				)}
				{this.props.variant === "secondary" && <ThemeModeIcon variant={this.props.variant} onClick={this.toggle} />}
			</Module>
		);
	}

	private toggle(): void {
		ThemeMode.getInstance().toggle();
	}

	private updateTheme(status: EThemeModeType) {
		this.setState({
			status
		});
	}

	public override componentDidMount() {
		this.removeOnSwitch = ThemeMode.getInstance().onSwitch(this.updateTheme)
	}

	public override componentWillUnmount() {
		this.removeOnSwitch();
	}
}

